import {
  game,
  ParticleEmitter,
  particleManager,
  THREE
} from '@powerplay/core-minigames'
import type { ParticleOptionsType } from '@powerplay/core-minigames'
import {
  type ParticleEmitters,
  ParticleNames,
  TexturesNames,
  type ParticleEmitterTimerType
} from './types'
import {
  gunShotSmokeNearGunConfig,
  pigeonShotSmokeConfig
} from './config'

export class ParticleEffects {

  /** Emittery v hre  */
  public emitters: ParticleEmitters = {}

  /** Zakladny konfig pre emitter */
  private _baseConfig?: ParticleOptionsType

  /** pomocny vektor */
  private helperVector = new THREE.Vector3()

  /** Timery pre jednorazove emittery */
  private emitterTimers: ParticleEmitterTimerType = {
    [ParticleNames.pigeonShotSmoke]: 0,
    [ParticleNames.gunShotSmokeNearGun]: 0,
    // [ParticleNames.gunShotSmokeLong1]: 0,
    // [ParticleNames.gunShotSmokeLong2]: 0,
    // [ParticleNames.gunShotSmokeLong3]: 0,
    // [ParticleNames.gunShotSmokeLong4]: 0,
    // [ParticleNames.gunShotSmokeLong5]: 0,
  }

  /** Getter base configu */
  public get baseConfig(): ParticleOptionsType {

    if (!this._baseConfig) throw new Error('Partikle baseconfig nie je definovany')
    return this._baseConfig

  }

  /**
   * Vytvorenie emitterov
   */
  public createEmitters() {

    this.emitters = {}
    this.createPigeonShotSmokeParticle()
    this.createNearGunSmokeParticle()

  }

  /**
   * Vyvorenie partiklov pre dym po rozbiti
   */
  private createPigeonShotSmokeParticle(): void {

    const config = {
      ...pigeonShotSmokeConfig.particlesConfig,
      scene: game.scene,
      texture: game.getTexture(TexturesNames.pigeonSmokeParticles)
    }

    particleManager.createEmitter(config)

    // cache
    this.emitters[ParticleNames.pigeonShotSmoke] = particleManager.getEmitter(ParticleNames.pigeonShotSmoke)

    // nastavime, aby boli particle vzdy navrchu
    const emitter = this.getEmitter(ParticleNames.pigeonShotSmoke)
    if (emitter) {

      const material = emitter.getParticle().getMaterial()
      material.depthTest = true
      material.depthWrite = false

      emitter.minSettingsQualityForRender = 1
      emitter.oneTimeEffect = true

      emitter.stopEmitter(false)

    }

  }

  /**
   * Vytvorenie partiklov pre prach/dym z pusky po vystrele - blizko hlavne
   */
  private createNearGunSmokeParticle(): void {

    const config = {
      ...gunShotSmokeNearGunConfig.particlesConfig,
      scene: game.scene,
      texture: game.getTexture(TexturesNames.gunShotParticles)
    }

    config.name = ParticleNames.gunShotSmokeNearGun

    particleManager.createEmitter(config)

    // cache
    this.emitters[config.name as ParticleNames] = particleManager.getEmitter(config.name)

    // nastavime, aby boli particle vzdy navrchu
    const emitter = this.getEmitter(config.name)
    if (emitter) {

      const material = emitter.getParticle().getMaterial() as THREE.ShaderMaterial
      material.blending = THREE.NormalBlending
      material.defaultAttributeValues.color = [0.8, 0.8, 0.8]
      material.needsUpdate = true
      console.log('emitter', emitter, material)

      emitter.minSettingsQualityForRender = 4
      emitter.oneTimeEffect = true

      emitter.stopEmitter(false)

    }

  }

  /**
   * Vrati emitter z cache
   * @param name - nazov emittera
   * @returns - emitter
   */
  public getEmitter(name: ParticleNames): ParticleEmitter | undefined {

    const emitter = this.emitters[name]
    if (!emitter) {

      console.warn('Nemame emitter', this.emitters, name)
      return undefined

    }
    return emitter

  }

  /**
   * Pustenie partiklov
   * @param emitterName - Nazov emittera
   * @param emitFrames - kolko frameov sa ma emitovat, default raz
   * @param position - Pozicia, na ktoru chceme efekt dat
   * @param direction - Smer, akym by mal ist efekt
   * @param multiplyVelocity - o ake cislo sa prenasobuje zakladna velocity
   */
  public startEmitter(
    emitterName: ParticleNames,
    emitFrames = 1,
    position?: THREE.Vector3,
    direction?: THREE.Vector3,
    multiplyVelocity?: number,
  ): void {

    // kedze ide o jednorazovy efekt, tak ho pustime iba na 1 frame
    this.emitterTimers[emitterName] = emitFrames + 1

    const emitter = this.getEmitter(emitterName)
    if (!emitter) return

    if (position) emitter.setPosition(position)
    if (direction) {

      const multiplyVelocityValue = multiplyVelocity ?? 1
      this.helperVector.set(0, 0, 0)
      const vector = this.helperVector.add(direction.clone().multiplyScalar(multiplyVelocityValue))
      emitter.setVelocity(vector)

    }

    emitter.startEmitter()

  }

  /**
   * Update metoda
   */
  public update(): void {

    // zastavenie particlov, ak treba
    for (const [key, value] of Object.entries(this.emitterTimers)) {

      if (value <= 0) continue
      const emitterName = key as ParticleNames

      this.emitterTimers[emitterName] -= 1
      if (this.emitterTimers[emitterName] === 0) {

        this.getEmitter(emitterName)?.stopEmitter(false)

      }


    }

  }

  /**
   * Vymazanie vsetkych partiklov vsetkych efektov
   */
  public deleteAllParticles(): void {

    for (const value of Object.values(this.emitters)) {

      value?.stopEmitter(true)

    }

  }

}

export const particleEffects = new ParticleEffects()
