import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface ActionButtonState {
  isShoot: boolean,
  isShootTutorial: boolean,
  touchStart: boolean,
  showJoystick: boolean,
  disabled: boolean
}

const initialState = () => ({
  isShoot: false,
  isShootTutorial: false,
  touchStart: false,
  showJoystick: false,
  disabled: true
})

const actionButtonState: Module<ActionButtonState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getActionState: (moduleState: ActionButtonState) => moduleState,
    getStart: (moduleState: ActionButtonState) => moduleState.touchStart,
    isShoot: (moduleState: ActionButtonState) => moduleState.isShoot,
    isShootTutorial: (moduleState: ActionButtonState) => moduleState.isShootTutorial,
    getShowJoystick: (moduleState: ActionButtonState) => moduleState.showJoystick,
    getActionDisabled: (moduleState: ActionButtonState) => moduleState.disabled
  },

  mutations: {
    RESET: (moduleState: ActionButtonState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: ActionButtonState, newState: ActionButtonState) => {

      moduleState.isShoot = newState.isShoot

    },
    SET_SHOOT_BUTTON: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.isShoot = newState

    },
    SET_SHOOT_BUTTON_TUTORIAL: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.isShootTutorial = newState

    },
    SET_TOUCH_START: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.touchStart = newState

    },
    SET_SHOW_JOYSTICK: (moduleState: ActionButtonState, newState: boolean) => {

      moduleState.showJoystick = newState

    },
    SET_IS_ACTION_DISABLED: (moduleState: ActionButtonState, disabled: boolean) => {

      moduleState.disabled = disabled

    }
  }
}

export default actionButtonState
