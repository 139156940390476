import { ShootingTypes } from '../types'
import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre strielanie
 */
export const shootingConfig = {

  /*
   * Aky typ strielania mame
   * type: ShootingTypes.hold,
   * type: ShootingTypes.press,
   */
  type: ShootingTypes.bar,

  /** Nastavenia sipu */
  pigeon: {

    /** Rychlost sipu v m/s */
    speed: {
      min: 27,
      max: 30,
      decelerationCoef: 0.995,
    },

    // Priemer sipu
    radius: 0.006849 / 2,

    startPositions: [
      new THREE.Vector3(6.4, 0.0001, 17.5),
      new THREE.Vector3(3.2, 0.0001, 17.5),
      new THREE.Vector3(0, 0.0001, 17.5),
      new THREE.Vector3(-3.2, 0.0001, 17.5),
      new THREE.Vector3(-6.4, 0.0001, 17.5),
    ],

    /** uhol */
    angleX: [
      {
        min: -45,
        max: -30,
      },
      {
        min: -10,
        max: 10,
      },
      {
        min: 30,
        max: 45,
      }
    ],
    angleY: {
      min: 22,
      max: 29,
    }


  },

  /** Casovy limit vo frameoch na vystrelenie */
  timeLimitShot: 10 * 30,

  // Pocet sekund, kolko sa caka v emocii
  waitInEmotion: 2.5,

  /** kolko striel mam na jeden pokus */
  shotsLimit: 2,

  /** body za vystrel podla */
  shotPoints: {
    first: 10,
    second: 9,
    percentsFirst: {
      firstAttempt: 100,
      secondAttempt: 90
    },
    percentsSecond: {
      firstAttempt: 80,
      secondAttempt: 60
    }
  },

  /** odchylka raycastov pri strelbe */
  raycastOffsets: {
    offsetX: 0.02,
    offsetY: 0.02,
    deviations: [
      {
        x: -1,
        y: -1
      },
      {
        x: 1,
        y: -1
      },
      {
        x: 0,
        y: 0
      },
      {
        x: -1,
        y: 1
      },
      {
        x: 1,
        y: 1
      },
      {
        x: -0.5,
        y: 0
      },
      {
        x: 0.5,
        y: 0
      },
      {
        x: 0,
        y: -0.5
      },
      {
        x: 0,
        y: 0.5
      },
    ]
  },

  /** Ci je zapnuty debug na strelu */
  debugShot: false,
}