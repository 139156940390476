<template>
  <trap-game />
</template>

<script lang="ts">
import TrapGame from '@/components/TrapGame.vue'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    TrapGame
  },
})

</script>

