<template>
  <mobile-button
    :type="type"
    :is-scaled="false"
    :disabled="getActionDisabled"
    class="pointer-events-auto"
    :disabled-animation-active="!isShoot"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import { inputsManager } from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    MobileButton
  },
  computed: {
    ...mapGetters({
      isShoot: 'ActionButtonState/isShoot',
      getActionDisabled: 'ActionButtonState/getActionDisabled'
    }),
    type() {

      return this.isShoot ? 'shoot' : 'call'

    }
  },
  methods: {
    performAction() {

      if (this.getActionDisabled) return

      disciplinePhasesManager.phaseAim.handleInputs(true)



    },
    endAction() {

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})

</script>

<style>

</style>
