import { Direction } from '../types'

/**
 * Konfig pre vietor
 */
export const windConfig = {

  /** Zmena vetra */
  change: {

    /** sanca na zmenu smeru */
    chanceToChangeDirection: 0.7,

    /** sanca na zmenu hodnoty */
    chanceToChangeValue: 0.7,

    /** Pocet frameov, za kolko sa zmeni vietor */
    frames: 10

  },

  /** Hodnoty pre gaussa pre prvotnu hodnotu */
  gaussFirstValue: {

    center: 4,
    difference: 5,
    min: 1,
    max: 8

  },

  /** Najnizsia hodnota vetra v m/s */
  minValue: 0,

  /** Najvyssia hodnota vetra v m/s */
  maxValue: 9,

  /** offsetova hodnota pre vychylenie strely v lubovolnom smere v m/s */
  offsetValue: 0.03,

  /** Debug veci */
  debug: {

    // ci je aktivny debug
    active: false,

    // debug smer
    direction: Direction.N,

    // debug hodnota
    value: 1

  }

}