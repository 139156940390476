import { THREE } from '@powerplay/core-minigames'

/**
 * Konfig pre specialne kamery
 */
export const customCameraConfig = {
  start: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
   * ako daleko od hraca ma byt kamera
   * typ THREE.Vector3(0, 0, 0) | undefined
   * hodnota undefined zresetuje hodnotu na deafult
   */
    idealOffset: new THREE.Vector3(-1, 2, -4),

    /*
   * ako daleko od hraca ma byt bod na ktory sa kamera pozera
   * typ THREE.Vector3(0, 0, 0) | undefined
   * hodnota undefined zresetuje hodnotu na deafult
   */
    idealLookAt: new THREE.Vector3(0, 0, 2),

    /*
   * ako rychlo ma kamera nasledovat hraca
   * typ number | undefined
   * hodnota undefined zresetuje hodnotu na deafult
   */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },

  emotion: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
     * ako daleko od hraca ma byt kamera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealOffset: new THREE.Vector3(0, 1, 1.2),

    /*
     * ako daleko od hraca ma byt bod na ktory sa kamera pozera
     * typ THREE.Vector3(0, 0, 0) | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    idealLookAt: new THREE.Vector3(0, 1.2, 0),

    /*
     * ako rychlo ma kamera nasledovat hraca
     * typ number | undefined
     * hodnota undefined zresetuje hodnotu na deafult
     */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  },
  replay: {

    // ci chceme zmeny aplikovat
    enabled: true,

    /*
   * ako daleko od hraca ma byt kamera
   * typ THREE.Vector3(0, 0, 0) | undefined
   * hodnota undefined zresetuje hodnotu na deafult
   */
    idealOffsets: [
      new THREE.Vector3(3, 2, -3),
      new THREE.Vector3(3, 2, 3),
      new THREE.Vector3(0, 2, -3),
      new THREE.Vector3(0, 2, 3),
      new THREE.Vector3(-3, 2, -3),
      new THREE.Vector3(-3, 2, 3),
      new THREE.Vector3(3, -2, -3),
      new THREE.Vector3(3, -2, 3),
      new THREE.Vector3(0, -2, -3),
      new THREE.Vector3(0, -2, 3),
      new THREE.Vector3(-3, -2, -3),
      new THREE.Vector3(-3, -2, 3),
    ],

    /*
   * ako daleko od hraca ma byt bod na ktory sa kamera pozera
   * typ THREE.Vector3(0, 0, 0) | undefined
   * hodnota undefined zresetuje hodnotu na deafult
   */
    idealLookAt: new THREE.Vector3(0, 0, 0),

    /*
   * ako rychlo ma kamera nasledovat hraca
   * typ number | undefined
   * hodnota undefined zresetuje hodnotu na deafult
   */
    coefSize: undefined,

    // velkost lerpu pri zmene kamery
    changeLerp: 1

  }
}