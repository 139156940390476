import {
  MaterialsNames,
  ModelsNames
} from '@/app/types'
import {
  game,
  THREE,
  playersManager,
  HairColorTypes,
  PlayerSex
} from '@powerplay/core-minigames'
import { player } from '../player'
import { materialsConfig } from '@/app/config/materialsConfig'
import { Athlete } from '..'
import { gameConfig } from '@/app/config'

/** Trieda pre spravu protihraca */
export class Opponent extends Athlete {

  /**
   * Konstruktor
   * @param uuid - UUID spera
   * @param materialIndex - index materialu
   */
  public constructor(public uuid: string, materialIndex: number) {

    super(uuid)
    this.materialIndex = materialIndex

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    let objectForClone
    if (playersManager.getPlayer().sex !== sex) {

      objectForClone = game.getObject3D('athlete_opponent')

    }
    console.log('HGE', objectForClone, this.uuid, playersManager.getPlayer().sex, sex)
    const athleteObject = objectForClone ?
      game.cloneSkeleton(objectForClone) :
      game.cloneSkeleton(player.athleteObject)

    // game.scene.add(athleteObject)

    materialsConfig[ModelsNames.athlete]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(MaterialsNames.athleteOpponentPrefix + this.materialIndex) as THREE.MeshBasicMaterial

    })

    materialsConfig[MaterialsNames.athleteHair]?.meshesArray?.forEach((meshName) => {

      const opponentMesh = athleteObject.getObjectByName(meshName) as THREE.Mesh
      if (!opponentMesh) return
      meshName += `_opponent_${this.materialIndex}`
      opponentMesh.name = meshName

      // musime nastavit material
      opponentMesh.material = game.materialsToUse.basic
        .get(`${MaterialsNames.athleteHairOpponentPrefix + this.materialIndex}`) as THREE.MeshBasicMaterial

    })
    return athleteObject

  }

  /**
   * Vytvorenie protihraca
   * @param positionIndex - Index pozicie
   */
  public create(athleteName: string, position: THREE.Vector3, rotation: THREE.Vector3): void {

    console.log('vytvaram protihraca...', this.uuid)

    super.create(athleteName, position, rotation)

    const sex = playersManager.getPlayerById(this.uuid)?.sex
    this.prefixSex = sex === PlayerSex.female ? 'f_' : ''

    // spravime zmeny s vlasmi a klobukmi + ruky schovame, ak nahodou boli
    this.setHair()

  }

  /**
   * Nastavenie vlasov a klobuku
   */
  private setHair(): void {

    const opponentSufix = `opponent_${this.materialIndex}`
    console.log('....', this.athleteObject)
    this.hair = this.athleteObject.getObjectByName(`${this.prefixSex}hair_low_${opponentSufix}`) as THREE.Mesh
    this.hairTail = this.athleteObject.getObjectByName(`${this.prefixSex}tail_low_${opponentSufix}`) as THREE.Mesh
    console.log(
      'HAIRRRRRS', this.hair, this.hairTail, this.prefixSex, `${this.prefixSex}hair_low_${opponentSufix}`,
      `${this.prefixSex}tail_low`
    )

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return

    const maxCountHairTypes = 3
    let hairTypesVisible = [1, 3, 6, 7]
    let hairTailTypesVisible = [3]

    if (athlete.sex === PlayerSex.female) {

      hairTypesVisible = [1, 2, 3, 5, 6, 7]
      hairTailTypesVisible = [2, 3, 5, 7]

    }

    this.hairType = athlete.hair ?? Math.floor(Math.random() * maxCountHairTypes) + 1
    if (this.hairType > maxCountHairTypes) this.hairType = maxCountHairTypes

    const material = this.hair.material as THREE.MeshBasicMaterial
    const hairColor = athlete.hairColor ?? HairColorTypes.brown
    material.color = new THREE.Color(gameConfig.hairColors[HairColorTypes[hairColor]]).convertSRGBToLinear()

    this.hair.visible = hairTypesVisible.includes(this.hairType)
    this.hairTail.visible = hairTailTypesVisible.includes(this.hairType)

  }

  /**
   * Vymazanie vsetkych callbackov z animations managera
   */
  public removeCallbacksFromAllAnimations(): void {

    this.animationsManager.removeCallbacksFromAllAnimations()

  }

}
