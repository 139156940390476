import {
  THREE,
  game,
  cameraManager,
  errorManager,
  playersManager,
  PlayerSex,
  corePhasesManager,
  HairColorTypes
} from '@powerplay/core-minigames'
import {
  modelsConfig,
  gameConfig,
  customCameraConfig
} from '@/app/config'
import { ModelsNames } from '../../../types'
import { Athlete } from '..'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

/**
 * Trieda pre hraca
 */
export class Player extends Athlete {

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    const meshAthleteName = modelsConfig[ModelsNames.athlete]?.mainMeshNames?.[0]
    if (!meshAthleteName) {

      throw new Error(errorManager.showBox('Mesh name for athlete was not defined'))

    }

    return game.getObject3D(meshAthleteName)

  }

  /**
   * Vytvorenie lyziara
   */
  public create(): void {

    console.log('vytvaram hraca...')

    this.uuid = playersManager.getPlayer().uuid

    this.prefixSex = playersManager.getPlayer().sex === PlayerSex.male ? '' : 'f_'

    super.create('Player')

    this.setHair()

  }

  /**
   * Nastavenie vlasov
   */
  private setHair(): void {

    this.hair = this.athleteObject.getObjectByName(`${this.prefixSex}hair_low`) as THREE.Mesh
    this.hairTail = this.athleteObject.getObjectByName(`${this.prefixSex}tail_low`) as THREE.Mesh
    console.log(
      'HAIRRRRRS', this.hair, this.hairTail, this.prefixSex, `${this.prefixSex}hair_low`,
      `${this.prefixSex}tail_low`,
      this.athleteObject
    )

    const athlete = playersManager.getPlayerById(this.uuid)
    if (!athlete) return

    const maxCountHairTypes = 3
    let hairTypesVisible = [1, 3, 6, 7]
    let hairTailTypesVisible = [3]

    if (athlete.sex === PlayerSex.female) {

      hairTypesVisible = [1, 2, 3, 5, 6, 7]
      hairTailTypesVisible = [2, 3, 5, 7]

    }

    this.hairType = athlete.hair ?? Math.floor(Math.random() * maxCountHairTypes) + 1
    if (this.hairType > maxCountHairTypes) this.hairType = maxCountHairTypes

    const material = this.hair.material as THREE.MeshBasicMaterial
    const hairColor = athlete.hairColor ?? HairColorTypes.brown
    material.color = new THREE.Color(gameConfig.hairColors[HairColorTypes[hairColor]]).convertSRGBToLinear()

    this.hair.visible = hairTypesVisible.includes(this.hairType)
    this.hairTail.visible = hairTailTypesVisible.includes(this.hairType)

  }

  /**
   * Konecna akcia pre hraca
   */
  public finishAction(): void {

    // reset kamery
    cameraManager.getMainCamera().up.set(0, 1, 0)

  }

  /**
   * changes config of camera
   * @param idealOffset - ideal shift of camera from player
   * @param idealLookAt - ideal place for camera to look at
   * @param coefSize - how fast should camera move (0-1)
   * @param changeLerp - how fast changes should be applied (0-1)
   * @param staticMovement - true, ak sa nema dat lerp na poziciu kamery
   * @param shakingActive - Nastavenie trasenia kamery
   * @param setShakingDefaults - Ci sa maju nastavit defaults pre trasenie kamery
   */
  public changeCameraSettings(
    idealOffset?: THREE.Vector3,
    idealLookAt?: THREE.Vector3,
    coefSize?: number,
    changeLerp?: number,
    staticMovement?: boolean,
    shakingActive = false,
    setShakingDefaults = false
  ): void {

    disciplinePhasesManager.phaseAim.specialCameraShakeActive = shakingActive

    if (setShakingDefaults) {

      disciplinePhasesManager.phaseAim.shakeVector.set(0, 0, 0)
      disciplinePhasesManager.phaseAim.shakeDefaults = {
        idealOffset,
        idealLookAt,
        coefSize,
        changeLerp,
        staticMovement
      }

    }

    cameraManager.changeIdeals(
      idealOffset,
      idealLookAt,
      coefSize,
      changeLerp,
      staticMovement
    )

  }

  /**
   * nastavime camera settings podla game configu
   * @param lerpSize - volitelny iny lerp ako v game configu
   * @param staticMovement - true, ak pojde pozicia bez lerpu
   */
  public setGameCameraSettings(
    lerpSize = customCameraConfig.start.changeLerp,
    staticMovement = false
  ): void {

    this.changeCameraSettings(
      customCameraConfig.start.idealOffset,
      customCameraConfig.start.idealLookAt,
      customCameraConfig.start.coefSize,
      lerpSize,
      staticMovement
    )

  }

  /**
   * changes camera render settings
   * @param near - how close to camera stuff should be rendered
   * @param far - how far from camera stuff should be rendered
   * @param fov - field of view of camera
   */
  public changeCameraRenderSettings(near?: number, far?: number, fov?: number): void {

    cameraManager.changeRenderSettings(near, far, fov)

  }

  /**
   * Nastavenie pozicie hraca na zaciatku pokusu
   */
  public setPositionOnAttemptStart(): void {

    // vypocitame si, kde mame byt dany pokus
    let attempt = corePhasesManager.disciplineActualAttempt
    if (attempt === 0) attempt += 1 // kvoli zaciatku pri hrani znova
    const index = (attempt - 1) % corePhasesManager.provisionalResultsFrequency
    this.athleteObject.position.set(
      gameConfig.startPosition.x + (index * gameConfig.nextPositionShift),
      gameConfig.startPosition.y,
      gameConfig.startPosition.z
    )

  }

}

export const player = new Player('')
