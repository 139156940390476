/**
 * Konfig pre trail efekt
 */
export const trailConfig = {

  /** velkost efektu */
  scale: 0.2,

  /** dlzka efektu */
  length: 10,

  /** set how often a new trail node will be added and existing nodes will be updated */
  advanceFrequency: 30,

  /** farba efektu */
  color: {
    r: 1,
    g: 1,
    b: 0,
    a: 0.4
  },

}
