import type { Module } from 'vuex'
import type { RootState } from '../index'
import { Direction } from '@/app/types'


export interface WindState {
  value: number,
  direction: Direction,
  showWind: boolean
}

const initialState = () => ({
  value: 0,
  direction: Direction.N,
  showWind: false
})

const windState: Module<WindState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getWindState: (moduleState: WindState) => moduleState,
  },

  mutations: {
    RESET: (moduleState: WindState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: WindState, newState: WindState) => {

      if (newState.value !== undefined) moduleState.value = newState.value
      if (newState.direction !== undefined) moduleState.direction = newState.direction

    },
    SET_SHOW_WIND: (moduleState: WindState, showWind: boolean) => {

      if (showWind !== undefined) moduleState.showWind = showWind

    },
  }
}

export default windState
