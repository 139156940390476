<template>
  <section
    class="tutorial"
    :class="isMobile() ? 'mobile-overlay' : ''"
  >
    <tutorial-tasks
      v-if="showObjectives"
      :tasks="tasks"
    />
    <tutorial-overlay
      v-if="
        tutorialState.anne.showAnne || tutorialState.tutorialMessage.showMessage
      "
      style="pointer-events: auto"
      @click.prevent=""
      @mousedown.prevent=""
      @mouseup.prevent=""
      @touchstart.prevent=""
      @touchend.prevent=""
    />
    <tutorial-anne
      v-if="tutorialState.anne.showAnne"
      :is-right="tutorialState.anne.isRight"
      instructor-name="stefecekova"
    />
    <tutorial-under-menu v-if="tutorialState.showUnderMenu" />
    <tutorial-message-box
      v-if="tutorialState.tutorialMessage.showMessage"
      :key="typeWrite"
      :type-write="typeWrite"
      :class="{ offsetBot: tutorialState.tutorialMessage.offset }"
      :header-text="$t('utTutorialStefecekova')"
      :text="getMessageText(tutorialState.tutorialMessage.message)"
      :color="tutorialState.tutorialMessage.color"
      :button-yellow-text="tutorialState.tutorialMessage.yellowText"
      :force-hide-white-text="tutorialState.tutorialMessage.yellowTextSpecial"
      @show-full-text="setTypewriteToInactive"
    />
    <tutorial-hand-settings
      v-if="tutorialState.mobile.show"
      style="pointer-events: auto"
      img-url="https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/trap/ui/tutorial/mobile_trap.jpg"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click-continue="continueTask"
    >
      <div class="mobiler">
        <div class="buttons">
          <div
            class="button-group"
            :class="{ reverse: gameSettingsState.isLeft }"
          >
            <img
              width="284"
              height="284"
              :src="`${pathAssets}/ui/tutorial/joystick.png`"
              alt="joystick"
            >
          </div>
          <div
            class="button-group"
            :class="{ reverse: !gameSettingsState.isLeft }"
          >
            <img
              width="284"
              height="284"
              :src="`${pathAssets}/ui/tutorial/button.png`"
              alt="button"
            >
          </div>
        </div>
      </div>
    </tutorial-hand-settings>
    <instruction-box
      v-if="tutorialState.showInstructionBox"
      style="bottom: 20%"
      :right-content-text="$t(tutorialState.instructionBoxText)"
    />
    <tutorial-buttons
      v-if="isMobile() && tutorialState.showTutorialInfo"
    />
    <tutorial-info-keys v-show="!isMobile() && (tutorialState.showTutorialInfo)" />
    <arrow-animation
      v-if="tutorialState.showRelease"
      position="bottom"
      :style="styleArrowAnimation"
    />
  </section>
</template>

<script lang="ts">
import {
  WindowAspect,
  TutorialAnne,
  TutorialMessageBox,
  TutorialTasks,
  TutorialOverlay,
  InstructionBox,
  ArrowAnimation,
  TutorialHandSettings
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import {
  MobileDetector,
  settings,
  tutorialManager
} from '@powerplay/core-minigames'
import type { TutorialObjective } from '@powerplay/core-minigames'
import TutorialUnderMenu from '@/components/Tutorial/TutorialUnderMenu.vue'
import { pathAssets } from '@/globals/globalvariables'


import { defineComponent } from 'vue'
import TutorialButtons from './TutorialButtons.vue'
import TutorialInfoKeys from './TutorialInfoKeys.vue'

export default defineComponent({
  name: 'TutorialLogicComponent',
  components: {
    TutorialAnne,
    TutorialMessageBox,
    TutorialTasks,
    TutorialOverlay,
    TutorialUnderMenu,
    InstructionBox,
    ArrowAnimation,
    TutorialButtons,
    TutorialInfoKeys,
    TutorialHandSettings
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      pathAssets
    }

  },
  computed: {
    ...mapGetters({
      // TODO: toto podla use case
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      tutorialState: 'TutorialState/getTutorialState',
      tutorialObjectives: 'TutorialState/getTutorialObjectives',
      typeWrite: 'TutorialCoreState/getTypeWriter',
      wind: 'WindState/getWindState',
      showObjectives: 'TutorialState/getShowTutorialObjectives'
    }),
    basicStyle() {

      return {
        'align-items': 'flex-end',
        width: '290px',
        height: '200px',
        padding: '20px'
      }

    },
    rotateValue() {

      return (Number(this.wind.direction) + 2) * 15 + 60

    },
    styleArrowAnimation() {

      return {
        left: '50%',
        bottom: MobileDetector.isMobile() ? '1.5rem' : '0.5rem',
        'transform-origin': 'center bottom',
        position: 'absolute',
        width: '0px',
        height: '85px'
      }

    }
  },

  watch: {
    tutorialState: {
      immediate: true,
      deep: true,
      handler() {

        this.updateTasks()

      }
    }
  },
  methods: {
    setTypewriteToInactive() {

      this.$store.commit('TutorialCoreState/SET_TYPE_WRITER', false)
      tutorialManager.setTypeWriting(false)

    },
    continueTask() {

      this.setThings()
      settings.saveValues()
      window.dispatchEvent(new Event('mousedown'))

    },
    setThings(): void {

      settings.update('quality', this.gameSettingsState.graphicsSettings)
      settings.update('sounds', this.gameSettingsState.volume ? 1 : 0)
      settings.update(
        'qualityAuto',
        this.gameSettingsState.graphicsAuto ? 1 : 0
      )
      settings.update('isLeft', this.gameSettingsState.isLeft ? 1 : 0)
      settings.update('aimSensitivity', this.gameSettingsState.aimSensitivity)
      settings.update('oneFinger', this.gameSettingsState.oneFinger ? 1 : 0)

    },
    updateTasks() {

      this.tasks = this.tutorialObjectives.map((objective: TutorialObjective) => {

        let color = 'blue'
        if (objective.passed) color = 'green'
        if (objective.failed) color = 'red'
        return {
          color,
          text: `${this.$t(objective.name).replace('[number]', '30') } ${this.tutorialState.resultPoints}/3`
        }

      })

    },
    getMessageText(text: string): string {

      if (text === 'tutorialText12-2' || text === 'tutorialText12-3') {

        return this.$t(text).replace('[number]', '15')

      }

      return this.$t(text)

    }
  }
})
</script>

<style lang="less">
.tutorial {

    &.mobile-overlay {
      .tutorial-anne, .tutorial-mobil, .tutorial-hand-bottom {
          position: fixed;
      }
    }

  .offsetBot {
    bottom: 40%;
  }

  .tutorial-tasks {
    position: absolute;
    left: 1%;
    top: 17%;
  }
}
.mobiler {
    height: 100%;

    .buttons {
        position: relative;
        width: 100%;
        height: 100%;

        .button-group {
            position: absolute;
            bottom: 70px;
            left: 70px;
            right: 70px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.reverse {
                flex-direction: row-reverse;
            }
        }
    }
}
</style>
