import {
  type TutorialObjective,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface TutorialState {
  anne: {
    showAnne: boolean,
    isRight: boolean
  },
  mobile: boolean,
  tutorialMessage: {
    message: string,
    showMessage: boolean,
    color: TutorialMessageColors,
    yellowText: string,
    yellowTextSpecial: boolean,
    offset: boolean
  },
  objectives: TutorialObjective[],
  settings: boolean,
  showTutorialInfo: boolean,
  instructionBoxText: string,
  showInstructionBox: boolean,
  resultPoints: number,
  showRelease: boolean,
  showObjectives: boolean,
}

const initialState = () => ({
  anne: {
    showAnne: false,
    isRight: false
  },
  mobile: false,
  tutorialMessage: {
    message: '',
    showMessage: false,
    color: TutorialMessageColors.blank,
    yellowText: '',
    yellowTextSpecial: false,
    offset: false
  },
  settings: false,
  objectives: [],
  showTutorialInfo: false,
  instructionBoxText: '',
  showInstructionBox: false,
  resultPoints: 0,
  showRelease: false,
  showObjectives: true,
})

const tutorialState: Module<TutorialState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getTutorialState: (moduleState: TutorialState) => moduleState,
    getTutorialObjectives: (moduleState: TutorialState) => moduleState.objectives,
    getSettings: (moduleState: TutorialState) => moduleState.settings,
    getShowTutorialInfo: (moduleState: TutorialState) => moduleState.showTutorialInfo,
    getShowRelease: (moduleState: TutorialState) => moduleState.showRelease,
    getShowTutorialObjectives: (moduleState: TutorialState) => moduleState.showObjectives,
  },

  mutations: {
    RESET: (moduleState: TutorialState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: TutorialState, newState: TutorialState) => {

      moduleState = newState

    },
    SET_ANNE: (
      moduleState: TutorialState,
      newValue: {
        showAnne: false,
        isRight: false
      }
    ) => {

      moduleState.anne = newValue

    },
    SET_MOBILE: (
      moduleState: TutorialState,
      newValue: boolean
    ) => {

      moduleState.mobile = newValue

    },
    SET_ANNE_IS_RIGHT: (moduleState: TutorialState, newValue: boolean) => {

      moduleState.anne.isRight = newValue

    },
    SET_SETTINGS: (moduleState: TutorialState, bool: boolean) => {

      moduleState.settings = bool

    },
    SET_MESSAGE: (
      moduleState: TutorialState,
      newState: {
        message: string,
        showMessage: boolean,
        color: TutorialMessageColors.blank,
        yellowText: string,
        yellowTextSpecial: boolean,
        offset: boolean,
      }
    ) => {

      moduleState.tutorialMessage = newState

    },
    SET_OBJECTIVES: (moduleState: TutorialState, objectives: TutorialObjective[]) => {

      moduleState.objectives = [...objectives]

    },
    SET_SHOW_TUTORIAL_INFO: (moduleState: TutorialState, showTutorialInfo: boolean) => {

      moduleState.showTutorialInfo = showTutorialInfo

    },
    SET_INSTRUCTION_BOX_TEXT: (moduleState: TutorialState, instructionBoxText: string) => {

      moduleState.instructionBoxText = instructionBoxText

    },
    SET_SHOW_INSTRUCTION_BOX: (moduleState: TutorialState, showInstructionBox: boolean) => {

      moduleState.showInstructionBox = showInstructionBox

    },
    SET_RESULT_POINTS: (moduleState: TutorialState, resultPoints: number) => {

      moduleState.resultPoints = resultPoints

    },
    SET_SHOW_RELEASE: (moduleState: TutorialState, showRelease: boolean) => {

      moduleState.showRelease = showRelease

    },
    SET_SHOW_OBJECTIVES: (moduleState: TutorialState, show: boolean) => {

      moduleState.showObjectives = show

    }

  }
}

export default tutorialState
