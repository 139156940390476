import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'
import { audioSprites } from './spriteAudioConfig'

/**
 * Trieda konfigu pre zvuky
 */
export const audioConfig: AudioObject[] = [

  ...audioSprites,
  {
    files: [
      AudioNames.audienceHyped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    group: AudioGroups.audience,
    category: AudioCategories.audience,
  },
  {
    files: [
      AudioNames.audienceQuiet
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: true,
    group: AudioGroups.audience,
    category: AudioCategories.audience,
  },
  {
    files: [
      AudioNames.gunShot
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    version: 240514,
    category: AudioCategories.misc,
  },
  {
    files: [
      AudioNames.commentatorIntro,
      AudioNames.commentatorIntro2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },
  {
    files: [
      AudioNames.commentatorBefore1stShot,
      AudioNames.commentatorBefore1stShot2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },
  {
    files: [
      AudioNames.commentatorProvisionalStandings,
      AudioNames.commentatorProvisionalStandings2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },
  {
    files: [
      AudioNames.commentatorFinalResults,
      AudioNames.commentatorFinalResults2
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    loadRandomCount: 1,
    category: AudioCategories.commentators,
    group: AudioGroups.commentators
  },

]
