import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const audioSprites: AudioObject[] = [
  {
    files: [
      AudioNames.spriteMisc
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    subset: true,
    randomizeSprite: true,
    version: 3,
    category: AudioCategories.sprite,
    sprite: {
      'trap_miss_call': [
        0,
        330.31746031746036
      ],
      'trap_target_call_man_var02': [
        2000,
        1088.1859410430836
      ],
      'trap_target_call_woman_var01': [
        5000,
        1203.2879818594101
      ],
      'trap_target_var03': [
        8000,
        626.9387755102046
      ]
    }
  },
  {
    files: [
      AudioNames.spriteClaps
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    category: AudioCategories.sprite,
    sprite: {
      'cheer_and_clap': [
        0,
        4429.07029478458
      ],
      'only_clap': [
        6000,
        4953.469387755103
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'VO_trap_hit_one_shot_var01': [
        0,
        1697.3242630385487
      ],
      'VO_trap_hit_one_shot_var012': [
        3000,
        1406.0090702947844
      ],
      'VO_trap_hit_two_shot_var01': [
        6000,
        2751.0204081632655
      ],
      'VO_trap_hit_two_shot_var012': [
        10000,
        2147.9365079365084
      ],
      'VO_trap_miss_var01': [
        14000,
        3734.988662131521
      ],
      'VO_trap_miss_var012': [
        19000,
        2364.829931972789
      ],
      'VO_trap_rank_1': [
        23000,
        7230.090702947845
      ],
      'VO_trap_rank_2_3': [
        32000,
        6188.548752834464
      ],
      'VO_trap_rank_4_5': [
        40000,
        4803.219954648526
      ],
      'VO_trap_rank_6': [
        46000,
        5267.482993197276
      ]
    }
  }
]