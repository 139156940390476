/**
 * Mena sekcie
 */
export enum SectionNames {
  mobileSidePick = 'mobileSidePick',
  startSection = 'startSection',
  startSectionSecond = 'startSectionSecond',
  startSectionThird = 'startSectionThird',
  startSectionFourth = 'startSectionFourth',
  startSectionFifth = 'startSectionFifth',
}

type NameKeys = keyof typeof SectionNames;
type NameKeyFields = { [key in NameKeys]: string }

export interface UiState extends NameKeyFields {
  NameKeyFields: () => void
}

export enum TutorialEventType {
  goodShot = 'goodShot',
  badShot = 'badShot',
  noShot = 'noShot',
  oneShot = 'oneShot',
  improvedShot = 'improvedShot',
  goodFinish = 'goodFinish',
  badFinish = 'badFinish',
  afterLoading = 'afterLoading',
  awaitingEvent = 'awaitingEvent'
}

export enum TutorialObjectiveIds {
  hit3 = 'tutorialTask1',
}
