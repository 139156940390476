import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre training
 */
export const trainingConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(2.4055707454681396, 0.9470177888870239, 5.568503379821777),
      endPosition: new THREE.Vector3(2.4055707454681396, 0.9470177888870239, 5.568503379821777),
      startRotation: new THREE.Vector3(1.5622226828077554, -0.006039947525735394, 0.6140575107549751),
      endRotation: new THREE.Vector3(1.5622226828077554, -0.006039947525735394, 0.6140575107549751),
      duration: 3,
      notSkippableFrames: 20,
      fov: 44.6,
    },
    [CameraStates.intro2]: undefined,
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(19.930490493774414, 3.371567964553833, -14.764122009277344),
      endPosition: new THREE.Vector3(14.490211486816406, 6.311642646789551, -2.0866875648498535),
      startRotation: new THREE.Vector3(1.5993733234197949, 0.013390631648367506, -2.7035481152163716),
      endRotation: new THREE.Vector3(1.5366855922855747, -0.013028444444158549, -2.7768674508746827),
      fov: 81,
      duration: 5
    },
    [CameraStates.disciplineIntro]: {
      startPosition: new THREE.Vector3(2.4055707454681396, 0.9470177888870239, 5.568503379821777),
      endPosition: new THREE.Vector3(4.175370693206787, 0.9148046970367432, 3.058197259902954),
      startRotation: new THREE.Vector3(1.5622226828077554, -0.006039947525735394, 0.6140575107549751),
      endRotation: new THREE.Vector3(1.5772008465766634, 0.004519277174799359, 0.6140695869039585),
      duration: 3,
      fov: 44.6,
      fovEnd: 59.4
    },
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: undefined,
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,
  framesPenalty: 1

}
