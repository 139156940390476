<template>
  <div class="info-key-positioner">
    <tutorial-keyboard-keys
      v-if="showSpace"
      style="right:0%; bottom: 0%; transform-origin: right bottom"
      :text="$t('webInfoShoot')"
      is-scaled
    >
      <div class="mouse-click" />
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpace"
      style="left:0%; bottom: 0%; transform-origin: left bottom"
      :text="$t('webInfoAim')"
      is-scaled
    >
      <div class="mouse-move" />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import { TutorialKeyboardKeys } from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialKeys',
  components: {
    TutorialKeyboardKeys
  },
  computed: {
    ...mapGetters({
      isLeft: 'InputsState/isLeft',
      isDisabled: 'InputsState/getDisabled',
      buttonsVisible: 'InputsState/getIsVisible',
      showJoystick: 'ActionButtonState/getShowJoystick',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      gamePhaseState: 'GamePhaseState/getGamePhaseState',
      movementButtonsActive: 'MovementButtonsState/getMovementButtonsActive',
      getActionDisabled: 'ActionButtonState/getActionDisabled',
      isShoot: 'ActionButtonState/isShoot'
    }),
    showSpace() {

      return this.isShoot
      // this.buttonsVisible && this.gamePhaseState.showUi &&
      //         !this.movementButtonsActive && this.isShoot && !this.getActionDisabled

    }
  }
})
</script>

<style lang="less">
    .info-key-positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }
        .keyboard-vertical-keys {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                width: 100px;
            }
            .tutorial-keyboard-header {
                padding: 0 20px;
                width: 200px;
                margin-bottom: 40px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .mouse-move {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-MOVE.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 126px;
            height: 132px;
        }
        .mouse-click {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-CLICK.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 20px 22px;
            width: 70px;
            height: 90px;
        }
        .tutorial-keyboard-keys.tutorial-keyboard-keys-ssm {
          margin: 0 100px;

          .tutorial-keyboard-header {
            .shrinking-text {
              padding: 0;
            }
          }

        }
    }
</style>
