import {
  ParticleMaterialType,
  THREE
} from '@powerplay/core-minigames'
import { ParticleNames } from '../types'

/**
 * Konfig pre particle prachu po trafeni holuba
 */
export const pigeonShotSmokeConfig = {

  /** nasobic rychlosti pre replay zobrazenie, aby to bolo viditelnejsie */
  velocityMultiplierReply: 1.7,

  /** originalna velocity */
  velocityOriginal: new THREE.Vector3(-0.5, -0.1, 0),

  // konfig pre partikle
  particlesConfig: {
    emitRate: 1,
    particleLife: 50,
    blending: THREE.NormalBlending,
    name: ParticleNames.pigeonShotSmoke,
    basePosition: new THREE.Vector3(0, 0, 0),
    particleSpan: new THREE.Vector3(0, 0, 0),
    scale: 1.5 * 1.41, // nasobenie kvoli okrajom na texture a ze particle system meni uvcka pri rotacii
    mass: 0.05,
    alpha: 0.8,
    gravitation: false,
    randomPower: 0,
    randomDrift: false,
    materialType: ParticleMaterialType.shader,
    velocity: new THREE.Vector3(0, 0, 0),
    vertexColors: false,
    rotation: [0, Math.PI],
    particleAmountPerEmit: 1,
    repeat: [1, 1],
    offset: [1, 1],
    easeValues: {
      scale: {
        endValue: 3 * 1.41, // nasobenie kvoli okrajom na texture a ze particle system meni uvcka pri rotacii
        easeFunction: 'power4.out'
      },
      alpha: {
        endValue: 0,
        easeFunction: 'power2.in'
      },
    }
  }

}

/*
 * specialne veci pre particle prachu/dymu po vystreleni z pusky, efekt hned za hlavnou pusky
 */
export const gunShotSmokeNearGunConfig = {

  // hodnota prenasobenia velocity, resp ako rychlo chceme, aby isiel efekt
  multiplyVelocity: 0.1,

  // kolko frameov sa bude emitovat
  emitFrames: 1,

  // pocet emitterov pre tento efekt
  emittersCount: 5,

  // konfig pre partikle
  particlesConfig: {

    emitRate: 0,
    particleLife: 15,
    blending: THREE.NormalBlending,
    name: ParticleNames.gunShotSmokeNearGun,
    basePosition: new THREE.Vector3(0, 0, 0),
    particleSpan: new THREE.Vector3(0, 0, 0),
    scale: [0.07, 0.13],
    mass: -0.001,
    alpha: [0.4, 0.5],
    gravitation: true,
    randomPower: 0,
    randomDrift: false,
    materialType: ParticleMaterialType.shader,
    velocity: new THREE.Vector3(0, 0, 0), // jedno aku dame, pocita sa dynamicky
    vertexColors: false,
    rotation: [0, Math.PI],
    rotationCoefs: [0.015, 0.03],
    rotaionDir: 1,
    randomizeRotationDir: true,
    particleAmountPerEmit: 1,
    repeat: [1, 1],
    offset: [1, 1],
    easeValues: {
      scale: {
        endValue: 1,
        easeFunction: 'power2.out'
      },
      alpha: {
        endValue: 0,
        easeFunction: 'power3.out'
      },
    }

  }

}

/*
 * specialne veci pre particle prachu/dymu po vystreleni z pusky, ktory ide do dialky s velocity
 */
export const gunShotSmokeLongConfig = {

  // hodnota prenasobenia velocity, resp ako rychlo chceme, aby isiel efekt
  multiplyVelocity: 9,

  // kolko frameov sa bude emitovat
  emitFrames: 1,

  // konfig pre particle
  particlesConfig: {

    emitRate: 0,
    particleLife: 30,
    blending: THREE.NormalBlending,
    name: ParticleNames.gunShotSmokeLong1,
    basePosition: new THREE.Vector3(0, 0, 0),
    particleSpan: new THREE.Vector3(0, 0, 0),
    scale: [0.07, 0.13],
    mass: -0.001,
    alpha: [0.2, 0.3],
    gravitation: true,
    randomPower: 0,
    randomDrift: false,
    materialType: ParticleMaterialType.shader,
    velocity: new THREE.Vector3(0, 0, 0), // jedno aku dame, pocita sa dynamicky
    velocitySlowDown: 0.15,
    vertexColors: false,
    rotation: [0, Math.PI],
    rotationCoefs: [0.015, 0.03],
    rotaionDir: 1,
    randomizeRotationDir: true,
    particleAmountPerEmit: 1,
    repeat: [1, 1],
    offset: [1, 1],
    // easeValues: {
    //   scale: {
    //     endValue: 0.8,
    //     easeFunction: 'power3.out'
    //   },
    //   alpha: {
    //     endValue: 0,
    //     easeFunction: 'power3.out'
    //   },
    // }

  }

}
