import type { TranslatesReplacements } from '@powerplay/core-minigames'

/**
 * Vymeny textov pre preklady - prekonkretnu disciplinu
 */
export const translatesReplacements: TranslatesReplacements[] = [

  {
    text: 'trainingTask13-0',
    replace: [
      {
        original: '[number]',
        new: '1'
      }
    ]
  },
  {
    text: 'trainingTask13-1',
    replace: [
      {
        original: '[number]',
        new: '2'
      }
    ]
  },
  {
    text: 'trainingTask13-2',
    replace: [
      {
        original: '[number]',
        new: '3'
      }
    ]
  },
  {
    text: 'trainingTask13-3',
    replace: [
      {
        original: '[number]',
        new: '4'
      }
    ]
  },
  {
    text: 'trainingTask13-4',
    replace: [
      {
        original: '[number]',
        new: '5'
      }
    ]
  },
  {
    text: 'tutorialTask13-1',
    replace: [
      {
        original: '[2to4]',
        new: '3'
      }
    ]
  },
  {
    text: 'tutorialText13-4',
    replace: [
      {
        original: '[2to4]',
        new: '3'
      }
    ]
  },
  {
    text: 'tutorialText13-8',
    replace: [
      {
        original: '[2to4]',
        new: '3'
      }
    ]
  },

]
