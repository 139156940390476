<template>
  <div class="start-stats-box">
    <start-countdown-time
      v-if="wind.showWind"
      text="Time to start"
      :time="timer"
      :style="{position: 'absolute', left: '50%', top: '1%',
               margin: '0.25em 0px'}"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  StartCountdownTime,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'StartStatsBox',
  components: {
    StartCountdownTime
  },
  mixins: [WindowAspect],
  data() {

    return {
      timer: 0
    }

  },
  computed: {
    ...mapGetters({
      wind: 'WindState/getWindState',
      uiState: 'UiState/getUiState',
      startTimer: 'StartPhaseState/getStartTimer'
    })
  },
  watch: {
    startTimer: {
      immediate: true,
      handler(value: number) {

        if (Math.ceil(value) !== this.timer) {

          this.timer = Math.ceil(value)

        }

      }
    }
  }
})
</script>

<style lang="less" scoped>
</style>
