<template>
  <section class="positioner">
    <div
      class="touch-overlay"
      style="height:100%;width:100%;pointer-events: auto;"
      @touchstart.prevent="touchStart"
      @mousedown.prevent="touchStart"
      @mouseup.prevent="endAction"
      @touchend.prevent="endAction"
    />
    <section
      v-if="buttonsVisible && gamePhaseState.showUi"
      class="positioner-tutorial-buttons"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between"
      >
        <arrow-animation
          v-if="showJoystick"
          position="bottom"
          style="left:0; bottom: 0%; transform-origin: left bottom; position: absolute; width: 572px; height: 472px;"
        />
        <movement-control
          v-show="showJoystick"
          :glow="showJoystick"
          :style="[
            {transform: `scale(${1})`, width: '100%'},
            shootPosition
          ]"
          class="bottom-left"
        />
        <section v-show="!showJoystick" />
        <section
          :style="{position: 'absolute', right: '0', bottom: '0'}"
        >
          <div
            class="bottom-right"
            :style="{transform: `scale(${scaleCoef})`}"
          >
            <arrow-animation
              position="bottom"
              :style="{
                left: 0,
                bottom: 0,
                'transform-origin': 'left bottom',
                position: 'absolute',
                width: '572px',
                height: '472px'
              }"
              :is-scaled="false"
            />
            <action-button
              v-if="!movementButtonsActive && showJoystick"
              :glow="showJoystick"
            />
          </div>
        </section>
      </section>
      <section
        v-else
        class="flex justify-between"
      >
        <section
          :style="{position: 'absolute', left: '0', bottom: '0'}"
        >
          <div
            class="bottom-left"
            :style="{transform: `scale(${scaleCoef})`}"
          >
            <arrow-animation
              position="bottom"
              style="left: 0; bottom: 0; transform-origin: left bottom; position: absolute; width: 572px; height: 472px"
              :is-scaled="false"
            />
            <action-button
              v-if="!movementButtonsActive && showJoystick"
              :glow="showJoystick"
            />
          </div>
        </section>
        <arrow-animation
          v-if="showJoystick"
          position="bottom"
          style="right: 0; bottom: 0%; transform-origin: right bottom; position: absolute; width: 572px; height: 472px"
        />
        <movement-control
          v-if="showJoystick"
          :glow="showJoystick"
          :style="[
            {transform: `scale(${1})`, width: '100%'},
            shootPosition
          ]"
          class="bottom-right"
        />
      </section>
    </section>
  </section>
</template>
<script lang="ts">
import {
  WindowAspect,
  ArrowAnimation
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import { inputsManager } from '@powerplay/core-minigames'
// import TutorialButtonStart from '@/components/Tutorial/TutorialButtonStart.vue'
import { defineComponent } from 'vue'
import ActionButton from '../Inputs/ActionButton.vue'
import MovementControl from '../Inputs/MovementControl.vue'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

export default defineComponent({
  name: 'TutorialButtons',
  components: {
    ArrowAnimation,
    MovementControl,
    ActionButton
  },
  mixins: [WindowAspect],
  data() {

    return {
      tasks: [],
      blockedEndAction: false,
      wasTypewriting: false
    }

  },
  computed: {
    ...mapGetters({
      isDisabled: 'ActionButtonState/getActionDisabled',
      buttonsVisible: 'InputsState/getIsVisible',
      showJoystick: 'ActionButtonState/getShowJoystick',
      startPhaseState: 'StartPhaseState/getStartPhaseState',
      gamePhaseState: 'GamePhaseState/getGamePhaseState',
      movementButtonsActive: 'MovementButtonsState/getMovementButtonsActive',
      gameSettingsState: 'GameSettingsState/getGameSettingsState',
      isShoot: 'ActionButtonState/isShoot'
    }),
    movementControlPosition() {

      return {
        position: 'absolute',
        height: '100%'
      }

    },
    shootPosition() {

      let left = `calc(50% - (572px * ${this.scaleCoef} / 2))`
      let right = ''
      if (!this.gameSettingsState.isLeft) {

        left = ''
        right = `calc(50% - (572px * ${this.scaleCoef} / 2))`

      }

      return {
        position: 'absolute',
        left,
        right,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: `calc(50% - (572px * ${this.scaleCoef} / 2))`,
        height: '100%'
      }

    }
  },
  methods: {
    touchStart() {

      if (!this.showJoystick || !this.isDisabled) return
      disciplinePhasesManager.phaseAim.handleInputs(true)

      console.warn('TouchStart')

    },
    endAction() {

      console.warn('EndAction')
      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})
</script>

<!-- TU nemoze byt scoped, pokial bude riesene .small-action-button-wrapper v tomto file -->
<style lang="less">

.nomobile-small-buttons-tutorial {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 375px;
    height: 375px;

    .bar {
        position: relative;
    }

    .arrow-wrapper {
        transform-origin: 50% 0%;
    }
    .bottom-right {
    transform-origin: bottom right;
    }
    .bottom-left {
    transform-origin: bottom left;
    }
}

.tutorial {

    .positioner {
        position: absolute;
        bottom: 0;
        width: 100%;

        .positioner-tutorial-buttons {
          position: fixed;
          width: 100vw;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          height: 100vh;

          .justify-between {
            width: 100%;
            height: 100%;
            position: relative;
          }

        }

    }

    .flex {
        display: flex;
        justify-content: space-between;
    }

    .row {
        flex-direction: row;
    }

    .row-reverse {
        flex-direction: row-reverse;
    }

    .buttons-set-wrapper {
        position: relative;
        width: 580px;
        height: 580px;
    }

    .buttons-position {
        position: absolute;
        top: -60px;
        left: 0;
        width: 100%;
        height: 200px;
    }

    .tutorial-anne {
        bottom: 0;
    }

    .sub-positions {

        .bar-right {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            width: 50%;
            height: 200px;
            position: absolute;
            right: 0;

            .small-action-button-wrapper {
                pointer-events: auto;
                justify-content: flex-start;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                padding: 0 20px;
            }
        }
        .bar-left {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 50%;
            height: 200px;
            position: absolute;
            left: 0;

            .small-action-button-wrapper {
                pointer-events: auto;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                align-items: flex-end;
                padding: 0 20px;
            }
        }
    }
    .bottom-right {
        transform-origin: bottom right;
    }
    .bottom-left {
        transform-origin: bottom left;
    }
    .top-right {
        transform-origin: top right;
    }
}
</style>
