import { THREE } from '@powerplay/core-minigames'
/**
 * Konfig pre opponenta/ov
 */
export const opponentConfig = {
  createData: [
    {
      position: new THREE.Vector3(6.4, 0.075, 0), // i * -3.2 po x osi su ostatne stanovista
      rotation: new THREE.Vector3(),
    },
    {
      position: new THREE.Vector3(3.2, 0.075, 0), // i * -3.2 po x osi su ostatne stanovista
      rotation: new THREE.Vector3(),
    },
    {
      position: new THREE.Vector3(0, 0.075, 0), // i * -3.2 po x osi su ostatne stanovista
      rotation: new THREE.Vector3(),
    },
    {
      position: new THREE.Vector3(-3.2, 0.075, 0), // i * -3.2 po x osi su ostatne stanovista
      rotation: new THREE.Vector3(),
    },
    {
      position: new THREE.Vector3(-6.4, 0.075, 0), // i * -3.2 po x osi su ostatne stanovista
      rotation: new THREE.Vector3(),
    },
    {
      position: new THREE.Vector3(6.4, 0.075, -3.2), // i * -3.2 po x osi su ostatne stanovista
      rotation: new THREE.Vector3(),
    },
  ]
}
