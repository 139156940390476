/** config pre holuba */
export const pigeonConfig = {
  pigeonMeshName: 'pigeon',
  pigeonFragmentsMeshName: [
    'pigeon_pigeon001_cell',
    'pigeon_pigeon001_cell001',
    'pigeon_pigeon001_cell005',
    'pigeon_pigeon001_cell002',
    'pigeon_pigeon001_cell007',
    'pigeon_pigeon001_cell003',
    'pigeon_pigeon001_cell006',
    'pigeon_pigeon001_cell009',
    'pigeon_pigeon001_cell004',
    'pigeon_pigeon001_cell008',
  ],

  /** velkost 3D a fyzickeho objektu */
  shapeSize: {

    // hlavne hodnoty s ktorymi pracujeme podla hracovej sily
    mainValues: [0.4, 0.35],

    // limity pre strengthDiffMax
    strengthDiffs: [30, 60],

    // limit pre dynamicke znizovanie velkosti holuba, strengthDiffMax musi byt vacsia ako tato hodnota
    limitForDynamicReduction: 60,

    // Aku hodnotu budeme ocitavat podla strengthDiffMax
    subValues: [0, 0.05, 0.1],

    // Aku percentualnu hodnotu budeme odcitavat v druhom kole po kazdom trafenom terci
    subValueSecondRound: 0.04,

    // kolko musi byt trafenych tercov v prvom kole, aby sa znizovala velkost
    firstRoundHits: 5

  },

  /** rychlost animacie, cim vacsie cislo, tym to ide rychlejsie */
  animationSpeed: 3,

  /** Scale pre holuba */
  scale: {
    normal: 5,
    replay: 2.5
  },

  /** Scale pre ulomky holuba */
  fragmentsScale: {
    normal: 0.05,
    replay: 0.025
  },

  /** veci pre replay */
  replay: {

    // sanca na zobrazenie replayu 0-1, cim nizsie cislo, tym mensia sanca
    chanceToShow: 0.8,

    // Maximalny pocet frameov na replay, ak trafime skoro, moze byt aj menej
    frames: 20,

    // koeficient pre spomalovanie kamery po rozbiti holuba - cim vacsie cislo, tym menej spomaluje
    coefCameraSlowDown: 0.9,

    // koeficient pre spomalovanie fragmentov holuba po jeho rozbiti - cim vacsie cislo, tym menej spomaluje
    coefFragmentsSlowDown: 0.96,

    // koeficient pre spomalovanie dymu po rozbilit holuba - cim nizsie cislo, tym menej spomaluje
    coefSmokeSlowDown: 0.12,

    // kolko trva rozbitie holuba v sekundach
    durationPigeonBreak: 3,

    // easing pre rozbitie holuba
    easePigeonBreak: 'power2.out',

    // koeficient pre gravitaciu
    coefGravitation: 0.01

  }

}