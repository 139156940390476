import type { Module } from 'vuex'
import type { RootState } from '../index'
import type { BlueBoxTextType } from '@/app/types'


export interface UiState {
  showTimeKeeper: boolean,
  showTrainingLayout: boolean,
  isTraining: boolean,
  blueBoxTextType: BlueBoxTextType,
  showEsc: boolean,
  joystickActive: boolean
}

const initialState = () => ({
  showTimeKeeper: false,
  showTrainingLayout: false,
  isTraining: false,
  blueBoxTextType: -1,
  showEsc: false,
  joystickActive: false
})

const uiState: Module<UiState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getUiState: (moduleState: UiState) => moduleState,
    getShowTimeKeeper: (moduleState: UiState) => moduleState.showTimeKeeper,
    getBlueBoxTextType: (moduleState: UiState) => moduleState.blueBoxTextType,
    getShowEsc: (moduleState: UiState) => moduleState.showEsc,
    getJoystickActive: (moduleState: UiState) => moduleState.joystickActive
  },

  mutations: {
    RESET: (moduleState: UiState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: UiState, newState: UiState) => {

      if (moduleState.showTimeKeeper !== undefined) {

        moduleState.showTimeKeeper = newState.showTimeKeeper

      }

      if (moduleState.showTrainingLayout !== undefined) {

        moduleState.showTrainingLayout = newState.showTrainingLayout

      }
      if (moduleState.isTraining !== undefined) {

        moduleState.isTraining = newState.isTraining

      }

    },
    SET_TIME_VISIBILITY: (moduleState: UiState, showTimeKeeper: boolean) => {

      moduleState.showTimeKeeper = showTimeKeeper

    },
    SET_BLUE_BOX_TEXT_TYPE: (moduleState: UiState, textType: BlueBoxTextType) => {

      moduleState.blueBoxTextType = textType

    },
    SET_SHOW_ESC: (moduleState: UiState, showEsc: boolean) => {

      moduleState.showEsc = showEsc

    },
    SET_JOYSTICK_ACTIVE: (moduleState: UiState, joystickActive: boolean) => {

      moduleState.joystickActive = joystickActive

    }
  }
}

export default uiState
