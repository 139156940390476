<template>
  <div
    class="info-component"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <info-box
      v-if="showTable"
    />
  </div>
  <div
    class="info-component-bluebox"
    :class="[isMobile() ? 'mobile-info-component' : '']"
  >
    <blue-box
      v-if="blueBoxTextType >= 0"
      :side="'bottom'"
      show-border
      :style="{left: '50%', width: '610px', height: '67px', position: 'unset'}"
    >
      <div
        v-if="isMobile()"
        class="blue-box-content-mobile"
      >
        {{ getTextMobile }}
      </div>
      <div
        v-else
        class="blue-box-content-desktop"
      >
        {{ $t('callTargetClick') }}
      </div>
    </blue-box>
  </div>
</template>

<script lang="ts">
import {
  BlueBox,
  InfoBox,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'
import { BlueBoxTextType } from '@/app/types/ui'

export default defineComponent({
  name: 'InfoComponent',
  components: {
    InfoBox,
    BlueBox
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      showTable: 'TableState/getShowTable',
      blueBoxTextType: 'UiState/getBlueBoxTextType'
    }),
    getLeftTextDesktop() {

      let text = ''
      if (this.isMobile()) return text

      if (this.blueBoxTextType === BlueBoxTextType.callTarget) {

        // String to find
        const searchString = '[b]'
        const s = this.$t('callTargetClick')

        const c = s.indexOf(searchString)
        if (c >= 0)
        {

          text = s.substring(0, c)

        }
        console.log(text)

      }

      return text

    },
    getRightTextDesktop() {

      let text = ''
      if (this.isMobile()) return text

      if (this.blueBoxTextType === BlueBoxTextType.callTarget) {

        console.log(this.$t('callTargetClick'))
        // String to find
        const searchString = '[/b]'
        const s = this.$t('callTargetClick')

        const c = s.indexOf(searchString)
        if (c >= 0)
        {

          text = s.substring(c + searchString.length)

        }

      }

      return text

    },
    getTextMobile() {

      let text = ''
      if (!this.isMobile()) return text
      if (this.blueBoxTextType === BlueBoxTextType.callTarget) text = this.$t('callTargetTap')

      return text

    }
  },
})

</script>

<style lang="less" scoped>
.info-component {
   position: absolute;
   bottom: .5rem;
   width: 100%;
   display: flex;
}
.info-component-bluebox {
   position: absolute;
   bottom: .5rem;
   width: 100%;
   display: flex;
   justify-content: center;

   &.mobile-info-component {
       bottom: 1.5rem;
   }

   .blue-box-content-mobile {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
   }

  .blue-box-content-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    font-size: 32px;
    font-weight: 600;
    color: #fff;
  }
}
</style>
