import {
  CameraStates,
  THREE
} from '@powerplay/core-minigames'

/**
 * Konfig pre kameru pre vsetky stavy
 */
export const cameraConfig = {

  /** Nastavenie pozicii a pohybov kamery pre vsetky stavy */
  tweenSettingsForCameraStates: {
    [CameraStates.intro]: {
      startPosition: new THREE.Vector3(-47.05678939819336, 19.320270538330078, 101.8334045410156),
      endPosition: new THREE.Vector3(-12.261110305786133, 12.548551559448242, 75.69667053222656),
      startRotation: new THREE.Vector3(1.520051070001947, -0.031186351586199763, 0.5505414325722726),
      endRotation: new THREE.Vector3(1.4866289643431314, -0.01485353818049772, 0.1742813044971115),
      duration: 5,
      notSkippableFrames: 20,
      fov: 55
    },
    [CameraStates.intro2]: {
      startPosition: new THREE.Vector3(-10.320981979370117, 4.034221324920654, 7.003098964691162),
      endPosition: new THREE.Vector3(-7.266172885894775, 4.157800006866455, 7.311622619628906),
      startRotation: new THREE.Vector3(1.5333249501546633, 0.01402092797562382, -0.3583962121158697),
      endRotation: new THREE.Vector3(1.5106086038190227, -0.0006000053306614083, 0.009596807224325444),
      duration: 3,
      notSkippableFrames: 20,
      fov: 50
    },
    [CameraStates.table]: {
      startPosition: new THREE.Vector3(19.930490493774414, 3.371567964553833, -14.764122009277344),
      endPosition: new THREE.Vector3(14.490211486816406, 6.311642646789551, -2.0866875648498535),
      startRotation: new THREE.Vector3(1.5993733234197949, 0.013390631648367506, -2.7035481152163716),
      endRotation: new THREE.Vector3(1.5366855922855747, -0.013028444444158549, -2.7768674508746827),
      fov: 81,
      duration: 5
    },
    [CameraStates.disciplineIntro]: [
      {
        startPosition: new THREE.Vector3(2.4055707454681396, 0.9470177888870239, 5.568503379821777),
        endPosition: new THREE.Vector3(4.175370693206787, 0.9148046970367432, 3.058197259902954),
        startRotation: new THREE.Vector3(1.5622226828077554, -0.006039947525735394, 0.6140575107549751),
        endRotation: new THREE.Vector3(1.5772008465766634, 0.004519277174799359, 0.6140695869039585),
        duration: 3,
        fov: 44.6,
        fovEnd: 59.4
      },
      {
        startPosition: new THREE.Vector3(-6.838029861450195, 1.1996608972549438, 4.2924981117248535),
        endPosition: new THREE.Vector3(-2.739490509033203, 1.272374153137207, 8.08217716217041),
        startRotation: new THREE.Vector3(1.541335506328199, -0.017258160449485055, 0.5296358663564072),
        endRotation: new THREE.Vector3(1.5152074011972418, -0.023424871691179572, 0.3983400055927418),
        duration: 3,
        fov: 60.2
      }
    ],
    [CameraStates.disciplineIntroSecond]: undefined,
    [CameraStates.discipline]: undefined,
    [CameraStates.disciplineOutro]: {
      startPosition: new THREE.Vector3(-2, 2, 3),
      endPosition: new THREE.Vector3(2, 2, 3),
      duration: 10,
      offsetCamera: true
    },
    [CameraStates.static]: undefined
  },
  distanceFromGround: 1.5,

  /** shake kamery pri emociach */
  shakeEmotions: {

    // ci je aktivny shake
    active: false,

    // aka je sanca, ze shake pokracuje v danom frame (0-1, pricom nizsie hodnoty budu znamenat menej vplyvny shake)
    chanceInFrame: 0.3,

    // maximalne vychylenie do lubovolnej strany v metroch (rataju sa aj minusove hodnoty)
    maxValueVector: 1,

    // koeficient pre shake, aky by mal byt silny
    power: 0.001
  }

}
