<template>
  <div :class="['emotion-messages', {'emotion-messages-mobile': isMobile()}]">
    <text-message-dynamic
      v-if="emotionMessagesState.showMessage"
      :style="getStyle"
      :first-line-text="textFirstLine"
      :first-line-text-second="textFirstLineSecond"
      :second-line-text="textSecondLine"
      :second-line-text-second="textSecondLineSecond"
      :transform-origin="'center left'"
      :duration="0.2"
      :delay-start="0"
      @finished="onFinished"
    />
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import {
  TextMessageDynamic,
  WindowAspect
} from '@powerplay/core-minigames-ui-ssm'
import { defineComponent } from 'vue'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'

export default defineComponent({
  components: {
    TextMessageDynamic
  },
  mixins: [WindowAspect],
  computed: {
    ...mapGetters({
      emotionMessagesState: 'EmotionMessagesState/getEmotionMessagesState',
    }),
    textFirstLine() {

      return this.$t(this.emotionMessagesState.firstLineText).toUpperCase()

    },
    textFirstLineSecond() {

      return this.$t(this.emotionMessagesState.firstLineTextSecond).toUpperCase()

    },
    textSecondLine() {

      let text = this.$t('winner').toUpperCase()
      if (this.emotionMessagesState.secondLineText !== 1) {

        text = this.$t('rankX').replace('%s', this.emotionMessagesState.secondLineText)

      }

      return text

    },
    textSecondLineSecond() {

      return this.$t(this.emotionMessagesState.secondLineTextSecond).toUpperCase()

    },
    getStyle() {

      const scale = this.isMobile() ? `calc(${window.innerWidth} / 1920)` : this.scaleCoef

      return {
        position: this.isMobile() ? 'fixed' : 'absolute',
        bottom: '10px',
        'transform-origin': 'bottom left',
        transform: `scale(${scale})`,
      }


    }
  },
  methods: {
    onFinished() {

      disciplinePhasesManager.phaseAim.afterEmotions()

    }
  }
})

</script>

<style lang="less" scoped>
.emotion-messages-mobile {
  position: fixed;
  left: 0;
}
</style>
