import { type DisplayMessage } from '@/app/types'
import store from '@/store'
import { modes } from '@powerplay/core-minigames'

export class StartPhaseStateManager {

  private store = store

  private textMessagesLinesFinishedEmits = [false, false, false]

  public disableInputs(): void {

    this.store.commit('InputsState/SET_DISABLED', true)
    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', true)

  }

  public hideButtons(): void {

    this.store.commit('ActionButtonState/SET_SHOOT_BUTTON', false)
    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)
    this.store.commit('InputsState/SET_VISIBLE', false)
    this.store.commit('InputsState/SET_DISABLED', true)

  }

  public enableInputs(disabled = false): void {

    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', !disabled)
    this.store.commit('InputsState/SET_VISIBLE', true)
    this.store.commit('InputsState/SET_DISABLED', disabled)

  }

  public showStartMessageInUI(message: DisplayMessage): void {

    this.store.commit('StartMessageState/SET_STATE', {
      showMessage: true,
      messageText: message.message,
      messageColor: message.color
    })

  }

  public postIntroUiState(): void {

    this.store.commit('UiState/SET_STATE', {
      showTimeKeeper: false,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })
    this.store.commit('MovementState/SET_HORIZONAL', true)
    // this.store.commit('InputsState/SET_DISABLED', false)

  }

  public finishPhaseUiState(): void {

    this.store.commit('UiState/SET_STATE', {
      showTimeKeeper: true,
      showTrainingLayout: modes.isTrainingMode(),
      isTraining: modes.isTrainingMode()
    })

  }

  public enableStartInputs(): void {

    console.log('enable start inputs')
    // this.store.commit('InputsState/SET_DISABLED', false)
    this.store.commit('ActionButtonState/SET_IS_ACTION_DISABLED', false)

  }

  public disableStartMessageInUI(message: DisplayMessage): void {

    this.store.commit('StartMessageState/SET_STATE', {
      showMessage: false,
      messageText: message.message,
      messageColor: message.color
    })

  }

  /**
   * Resetovanie text message dokoncenych emitov
   * @param activeFirst - ci je aktivny prvy riadok
   * @param activeSecond - ci je aktivny druhy riadok
   * @param activeThird - ci je aktivny treti riadok
   */
  public resetTextMessageFinishedEmits(activeFirst: boolean, activeSecond: boolean, activeThird: boolean): void {

    this.hideAllTextMessages()
    this.textMessagesLinesFinishedEmits = [!activeFirst, !activeSecond, !activeThird]

  }

  /**
   * Schovanie text message hlasky
   * @param lineNumber - Cislo riadku, ktore malo emit konca
   */
  public hideTextMessage(lineNumber: number): void {

    this.textMessagesLinesFinishedEmits[lineNumber - 1] = true

    if (
      this.textMessagesLinesFinishedEmits[0] &&
      this.textMessagesLinesFinishedEmits[1] &&
      this.textMessagesLinesFinishedEmits[2]
    ) {

      this.hideAllTextMessages()

    }

  }

  /**
   * Schovanie vsetkych message hlasok
   */
  public hideAllTextMessages(): void {

    store.commit('TextMessageState/SET_STATE', {
      showFirstLine: false,
      showSecondLine: false,
      showThirdLine: false,
      firstLineText: '',
      firstLineTextType: 0,
      secondLineTextType: 0,
      secondLineLeftNumber: 0,
      showMessage: false,
      showType: 0
    })

  }

}

export const startPhaseStateManager = new StartPhaseStateManager()
