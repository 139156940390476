<template>
  <instructions-component
    class="pointer-events-auto midder"
    :header="$t('instructions')"
    :button-text="$t('continue')"
    :cards="getInstructions"
    show-button
    @button-click="closeInstructions"
  />
</template>

<script lang="ts">
import { Instructions } from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'
import {
  CorePhases,
  corePhasesManager,
  modes,
  requestManager,
  tutorialManager
} from '@powerplay/core-minigames'
import { pathAssets } from '@/globals/globalvariables'

import { defineComponent } from 'vue'
import { gameConfig } from '@/app/config'

export default defineComponent({
  name: 'InstructionComponent',
  components: {
    InstructionsComponent: Instructions
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close-instructions'],
  computed: {
    ...mapGetters({
      showButton: 'InstructionsState/getShowButton'
    }),
    getInstructions() {

      this.$store.commit('BlurState/SET_IS_ACTIVE', true)
      const { disciplineID } = requestManager

      if (modes.isTrainingMode()) {

        return [
          {
            imgSrc: `${pathAssets}/ui/instructions/training-1.jpg?3`,
            header: this.$t(`instructionsDisciplineTraining${disciplineID}Cell1Title`).replace(
              '[number]',
              gameConfig.numberOfAttemptsTraining.toString()
            ),
            text: [this.$t(`instructionsDisciplineTraining${disciplineID}Cell1Text1`)]
          },
          {
            imgSrc: `${pathAssets}/ui/instructions/training-2.jpg?3`,
            header: this.$t(`instructionsDisciplineTraining${disciplineID}Cell2Title`),
            text: [this.$t(`instructionsDisciplineTraining${disciplineID}Cell2Text1`)]
          },
          {
            imgSrc: `${pathAssets}/ui/instructions/training-3.jpg?3`,
            header: this.$t(`instructionsDisciplineTraining${disciplineID}Cell3Title`),
            text: [this.$t(`instructionsDisciplineTraining${disciplineID}Cell3Text1`)]
          }
        ]

      }

      const mobilePart = this.isMobile ? 'Mobile' : ''

      return [
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-1${mobilePart}.png?3`,
          header: this.$t(`instructionsDiscipline${disciplineID}Cell1Title`),
          text: [this.$t(`instructionsDiscipline${disciplineID}Cell1Text1${mobilePart}`)]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-2${mobilePart}.png?3`,
          header: this.$t(`instructionsDiscipline${disciplineID}Cell2Title`),
          text: [this.$t(`instructionsDiscipline${disciplineID}Cell2Text1${mobilePart}`)]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-3.jpg?3`,
          header: this.$t(`instructionsDiscipline${disciplineID}Cell3Title`),
          text: [this.$t(`instructionsDiscipline${disciplineID}Cell3Text1`)]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-4.jpg?3`,
          header: this.$t(`instructionsDiscipline${disciplineID}Cell4Title`).replace(
            '[number]',
            gameConfig.numberOfAttempts.toString()
          ),
          text: [this.$t(`instructionsDiscipline${disciplineID}Cell4Text1`)]
        }
      ]

    }
  },
  methods: {
    closeInstructions(): void {

      // pokial mame prvotne instrukcie, tak ich zavrieme
      if (corePhasesManager.isActivePhaseByType(CorePhases.instructions)) {

        corePhasesManager.getActualPhase()?.finishPhase()

      }
      this.$emit('close-instructions')
      tutorialManager.blockInputsManually()

    }
  }
})
</script>

<style lang="less" scoped>
</style>
