import store from '@/store'
import {
  tutorialManager,
  TutorialSectionType,
  game,
  modes,
  TutorialMessageColors,
  MobileDetector,
  gsap,
  corePhasesManager,
  requestManager
} from '@powerplay/core-minigames'
import {
  PlayerStates,
  SectionNames,
  TutorialEventType,
  TutorialObjectiveIds
} from '../../types'
import { tutorialObjectives } from './TutorialObjectives'
import { tutorialUIChange } from './TutorialUIChange'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { player } from '@/app/entities/athlete/player'
import { shootingConfig } from '@/app/config'

/**
 *  Tutorial tasky ktore maju aj logiku v sebe na ovladanie tej ktorej udalosti
 */
export class TutorialFlow {

  /** ci je prvy pokus */
  public firstAttempt = true

  /** ci bol prvy pokus miss */
  private needsImprovement = false

  /** kolko holubov sme zostrelili */
  public pigeonsHit = 0

  /** kolko krat dostal hlasku 7 */
  public message7Count = 0

  /** kolko krat dostal hlasku 8 */
  public message8Count = 0

  /** ci uz bol prvy afterLoading */
  public wasAfterLoading = false

  /** kolko holubov sme vypustili */
  public pigeonReleased = 0

  /** ci ma byt cursor visible */
  public cursorVisible = false

  /** tween na timer pri prvom pokuse */
  public firstAttemptTimer?: gsap.core.Tween

  private activeEventType = TutorialEventType.awaitingEvent

  public setObjectivesInit(): void {

    const objectives = [
      {
        id: TutorialObjectiveIds.hit3 as string,
        passed: false,
        failed: false,
        name: `tutorialTask${requestManager.disciplineID}-1`
      },
    ]
    tutorialObjectives.setObjectives(objectives)

  }

  /**
   * Inicializacia
   */
  public init(): void {

    this.cursorVisible = true
    const tutorialSections = [
      {
        name: SectionNames.mobileSidePick,
        id: 0,
        type: TutorialSectionType.storyInput,
        sectionLogicFinish: () => {

          store.commit('BlurState/SET_IS_ACTIVE', false)
          store.commit('InputsState/SET_VISIBLE', true)
          store.commit('TutorialState/SET_SHOW_OBJECTIVES', true)

        }
      },
      {
        name: SectionNames.startSection,
        id: 1,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          document.body.style.cursor = 'auto'
          disciplinePhasesManager.phaseAim.unlockPointer()

        },
        sectionLogicFinish: () => {

          disciplinePhasesManager.phaseStart.finishPhase()
          player.setState(PlayerStates.loading)
          this.resetTypeWrite()
          console.log('end start phase')

        }
      },
      {
        name: SectionNames.startSectionSecond,
        id: 2,
        type: TutorialSectionType.storyInput,
        sectionLogicIntro: () => {

          document.body.style.cursor = 'auto'
          this.cursorVisible = true

        },
        sectionLogicFinish: () => {

          disciplinePhasesManager.phaseAim.tryToLockPointer()
          this.cursorVisible = false

        }
      },
      {
        name: SectionNames.startSectionThird,
        id: 3,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionFourth,
        id: 4,
        type: TutorialSectionType.gameEvent
      },
      {
        name: SectionNames.startSectionFifth,
        id: 5,
        type: TutorialSectionType.gameEvent,
        sectionLogicIntro: () => {

          this.resetTypeWrite()
          game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

        }
      },
    ]

    tutorialManager.setTutorialSections(tutorialSections)

    const firstTutorialStrings = ['chooseFirstDisciplineReturnFromMinigame', 'chooseFirstDisciplineContinue']
    if (MobileDetector.isMobile() && firstTutorialStrings.includes(requestManager.TUTORIAL_ID ?? '')) {

      store.commit('InputsState/SET_VISIBLE', false)
      store.commit('TutorialState/SET_SHOW_OBJECTIVES', false)
      return

    }

    tutorialManager.setActualSectionId(1)

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    // this.checkInput()
    tutorialUIChange.update()
    tutorialObjectives.update()

  }

  /**
   * Kontrola inputov
   */
  public checkInput(): void {

    if (TutorialSectionType.gameEvent === tutorialManager.getActualSectionType()) {

      this.eventActionPressed()

    }

  }

  public eventActionTrigger(eventType: TutorialEventType): void {

    if (!modes.isTutorial()) return
    this.activeEventType = eventType


    if (eventType === TutorialEventType.goodShot) {

      this.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-4`)
      tutorialUIChange.setAnne(true)
      return

    }
    if (eventType === TutorialEventType.noShot) {

      this.pauseGame()
      const text = MobileDetector.isMobile() ?
        `tutorialText${requestManager.disciplineID}-5m` :
        `tutorialText${requestManager.disciplineID}-5`
      tutorialUIChange.setMessage(true, text)
      tutorialUIChange.setAnne(false)
      store.commit('TutorialState/SET_SHOW_TUTORIAL_INFO', true)
      this.message7Count += 1
      return

    }
    if (eventType === TutorialEventType.oneShot) {

      this.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-6`)
      tutorialUIChange.setAnne(true)
      this.message8Count += 1
      return

    }
    if (eventType === TutorialEventType.badShot) {

      this.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-7`)
      tutorialUIChange.setAnne(true)
      return

    }
    if (eventType === TutorialEventType.improvedShot) {

      if (corePhasesManager.disciplineActualAttempt === corePhasesManager.disciplineAttemptsCount) {

        return

      }
      this.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-8`)
      tutorialUIChange.setAnne(true)
      this.needsImprovement = false
      return

    }
    if (eventType === TutorialEventType.badFinish) {

      this.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-9`, TutorialMessageColors.red)
      tutorialUIChange.setAnne(true)
      return

    }
    if (eventType === TutorialEventType.goodFinish) {

      this.pauseGame()
      tutorialUIChange.setMessage(true, `tutorialText${requestManager.disciplineID}-10`, TutorialMessageColors.green)
      tutorialUIChange.setAnne(true)
      return

    }
    if (this.wasAfterLoading) {

      this.activeEventType = TutorialEventType.awaitingEvent
      return

    }
    if (eventType === TutorialEventType.afterLoading) {

      this.pauseGame()
      this.wasAfterLoading = true
      const text = MobileDetector.isMobile() ?
        `tutorialText${requestManager.disciplineID}-3m` :
        `tutorialText${requestManager.disciplineID}-3`
      tutorialUIChange.setMessage(true, text)
      tutorialUIChange.setAnne(false)
      store.commit('TutorialState/SET_SHOW_TUTORIAL_INFO', true)
      if (!MobileDetector.isMobile()) store.commit('ActionButtonState/SET_SHOOT_BUTTON_TUTORIAL', true)

    }

  }

  /**
   * Pauznutie hry v tutoriali pre message
   */
  private pauseGame(): void {

    disciplinePhasesManager.phaseAim.unlockPointer()
    document.body.style.cursor = 'auto'
    this.cursorVisible = true
    this.resetTypeWrite()
    game.pauseGame()

  }

  /** Reset typewrite */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    store.commit('TutorialCoreState/SET_TYPE_WRITER', true)

  }

  public eventActionPressed(): void {

    if (game.paused) game.resumeGame()
    tutorialUIChange.setMessage(false, '')
    tutorialUIChange.setAnne(false)

    if (this.activeEventType === TutorialEventType.afterLoading) {

      store.commit('ActionButtonState/SET_SHOOT_BUTTON_TUTORIAL', false)
      disciplinePhasesManager.phaseAim.setReady(true)
      if (this.firstAttempt) {

        this.firstAttemptTimer = gsap.to({}, {
          onComplete: () => {

            store.commit('TutorialState/SET_SHOW_RELEASE', true)

          },
          duration: 5
        })

      }

    }
    if (
      [
        TutorialEventType.badFinish,
        TutorialEventType.goodFinish,
        TutorialEventType.afterLoading
      ].includes(this.activeEventType)
    ) {

      tutorialManager.nextSection()

    }

    disciplinePhasesManager.phaseAim.tryToLockPointer()
    this.cursorVisible = false
    this.activeEventType = TutorialEventType.awaitingEvent
    store.commit('TutorialState/SET_SHOW_TUTORIAL_INFO', false)

  }

  /**
   * Nastavenie veci po trafeni holuba
   */
  public pigeonHit(): void {

    if (!modes.isTutorial()) return

    this.pigeonsHit += 1

    if (this.pigeonsHit >= 3) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.hit3)
      this.eventActionTrigger(TutorialEventType.goodFinish)

    } else {

      if (this.firstAttempt) {

        tutorialFlow.eventActionTrigger(TutorialEventType.goodShot)

      } else if (this.needsImprovement) {

        tutorialFlow.eventActionTrigger(TutorialEventType.improvedShot)

      }

    }
    store.commit('TutorialState/SET_RESULT_POINTS', this.pigeonsHit)
    // store.commit('TutorialState/SET_SHOW_INSTRUCTION_BOX', false)
    this.firstAttempt = false

  }

  /**
   * Nastavenie veci po netrafeni holuba
   * @param shotsFired - number
   */
  public pigeonMiss(shotsFired: number): void {

    if (corePhasesManager.disciplineActualAttempt === corePhasesManager.disciplineAttemptsCount) {

      return

    }
    if (shotsFired === 0) {

      tutorialFlow.eventActionTrigger(TutorialEventType.noShot)

    }
    if (shotsFired === shootingConfig.shotsLimit - 1) {

      tutorialFlow.eventActionTrigger(TutorialEventType.oneShot)

    }
    if (shotsFired === shootingConfig.shotsLimit && this.firstAttempt) {

      tutorialFlow.eventActionTrigger(TutorialEventType.badShot)

    }
    if (this.firstAttempt) {

      this.needsImprovement = true

    }
    this.firstAttempt = false

  }

}

export const tutorialFlow = new TutorialFlow()
