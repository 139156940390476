/** Nazvy zvukov */
export enum AudioNames {

  spriteCommentators = 'commentators',
  spriteMisc = 'misc',
  spriteClaps = 'claps',

  audienceHyped = 'audience-hyped4',
  audienceQuiet = 'audience-noise',

  gunShot = 'trap_shot',
  targetMissCall = 'trap_miss_call',
  targetCallMan = 'trap_target_call_man_var02',
  targetCallWoman = 'trap_target_call_woman_var01',
  targetHit = 'trap_target_var03',

  clap = 'only_clap',
  cheerClap = 'cheer_and_clap',

  commentatorIntro = 'VO_trap_intro_var01',
  commentatorIntro2 = 'VO_trap_intro_var012',
  commentatorBefore1stShot = 'VO_trap_before_1st_shot_var01',
  commentatorBefore1stShot2 = 'VO_trap_before_1st_shot_var012',
  commentatorProvisionalStandings = 'VO_trap_provisional_standings_var01',
  commentatorProvisionalStandings2 = 'VO_trap_provisional_standings_var012',
  commentatorFinalResults = 'VO_trap_final_results_var01',
  commentatorFinalResults2 = 'VO_trap_final_results_var012',
  commentatorHitOneShot = 'VO_trap_hit_one_shot_var01',
  commentatorHitOneShot2 = 'VO_trap_hit_one_shot_var012',
  commentatorHitTwoShot = 'VO_trap_hit_two_shot_var01',
  commentatorHitTwoShot2 = 'VO_trap_hit_two_shot_var012',
  commentatorMiss = 'VO_trap_miss_var01',
  commentatorMiss2 = 'VO_trap_miss_var012',
  commentatorRank1 = 'VO_trap_rank_1',
  commentatorRank23 = 'VO_trap_rank_2_3',
  commentatorRank45 = 'VO_trap_rank_4_5',
  commentatorRank6 = 'VO_trap_rank_6'

}

/** Kategorie zvukov */
export enum AudioCategories {

  audience = 'audience',
  misc = 'misc',
  commentators = 'commentators',
  sprite = 'sprites'

}

/** skupiny zvukov */
export enum AudioGroups {

  commentators = 'commentators',
  audience = 'audience'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumePlaceTypes {

  start = 'start',
  stop = 'stop'

}

/** Typy pre zmenu hlasitosti divakov */
export enum AudienceChangeVolumeTypes {

  increase = 'increase',
  decrease = 'decrease'

}

/** Typy sektorov pre hlasitost */
export enum AudienceVolumeSectorTypes {

  full = 'full',
  graduallyDecrease = 'graduallyDecrease',
  graduallyIncrease = 'graduallyIncrease',
  min = 'min'

}
