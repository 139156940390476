import store from '@/store'
import {
  THREE,
  MobileDetector,
  game,
  settings,
  SettingsTypes
} from '@powerplay/core-minigames'
import { Direction } from '@/app/types'
import { aimConfig } from '@/app/config'
import { wind } from '@/app/entities/athlete/Wind'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'

/**
 * manazer vychylovania pri strelbe
 */
export class AimingDirectionManager {

  /** aiming target point obj name */
  private readonly AIMING_TARGET_POINT = 'aiming_target_point'

  /** target point obj prefix */
  private readonly TARGET_POINT = 'target_point'

  /** kolko smerov mame */
  private CHANGE_DIRECTION_COUNT = 8

  /** nahodne vygenerovany smer */
  private actualDirection = this.getRandomDirection()

  /** posledna pozicia mysy */
  private lastMousePosition = new THREE.Vector2()

  /** aktualna pozicia mysy */
  public actualMousePosition = new THREE.Vector2()

  /** o kolko posuvame terc podla mysy */
  public mouseStep = new THREE.Vector2()

  /** velkost hry */
  private gameSize = new THREE.Vector2()

  /** pocitadlo framov pre zmenu smeru pri vychylovani */
  private changeFramesCount = 0

  /** miesto kam mierime */
  public aimingPoint = new THREE.Mesh()

  /** miesto na terci kam by sa malo vystrelit */
  public targetPoint = new THREE.Mesh()

  /** miesto na terci kam by sa malo vystrelit, keby nebolo vetra */
  public targetPointOriginal = new THREE.Mesh()

  /** odchylka */
  public deviation = 0

  /** smer odchylky */
  public direction = Direction.N

  /** velkost kroku v kazdom smere */
  public directionStep: Record<number, THREE.Vector2> = {}

  /** po kolkych % obrazovky presunieme target o 100% - podla nastavenia citlivosti mierenia */
  public screenToMaxTarget = new THREE.Vector2()

  /** citlivost joystiku - podla nastavenia citlivosti mierenia */
  public joystickStep = new THREE.Vector2()

  /** Limity na hybanie sa */
  private limitsToMove = {
    minX: 0,
    maxX: 0,
    minY: 0,
    maxY: 0
  }

  /** min step vychylovania */
  private minStepAuto = 0

  /** max step vychylovania */
  private maxStepAuto = 0

  /** ci sme urobili init shift */
  public isInitShiftDone = false

  /** Percenta pre shake */
  private shakePercent = 0

  /** Aktualny frame pre zotrvacnost */
  private frameLag = 0

  /** Posledny nastaveny offset pre pohyb */
  private lastMoveOffset = new THREE.Vector2()

  /** Offset pre pohyb pre potreby pocitania zotrvacnosti */
  private lagOffsetOriginal = new THREE.Vector2()

  /** Aktualna hodnota zotrvacnosti */
  private actualLag = new THREE.Vector2()

  /** helper vector pre diff */
  private diffHelperVector = new THREE.Vector2()

  /** Ci bol pri aplikovani zotrvacnosti */
  private movingInLag = false

  /** Raycast */
  private raycast = new THREE.Raycaster()

  /** Vector pre raycast smerom na terc */
  private readonly RAYCAST_DIR_VECTOR = new THREE.Vector3(0, 0, 1)

  /**
   * Inicializacia
   */
  public init(): void {

    this.createDirectionSteps()
    this.setLimitsToMove()

  }

  /**
   * Nastavenie limitov na pohyb
   */
  public setLimitsToMove(): void {

    const middlePosition = aimConfig.targetCenterPosition

    this.limitsToMove.minX = middlePosition.x - aimConfig.movementRange.x
    this.limitsToMove.maxX = middlePosition.x + aimConfig.movementRange.x
    this.limitsToMove.minY = middlePosition.y - aimConfig.movementRange.y
    this.limitsToMove.maxY = middlePosition.y + aimConfig.movementRange.y

  }

  /**
   * ziskame nahodny smer
   * @returns nahodne cislo podla poctu smerov
   */
  private getRandomDirection(): number {

    return Math.floor(Math.random() * this.CHANGE_DIRECTION_COUNT)

  }

  /**
   * Aktualizovanie
   */
  public update(): void {

    this.updateAimingPosition()

  }

  /**
   * vytvorime objekt v ktorom si zaznacime zakladne velkosti vychylovania
   */
  private createDirectionSteps(): void {

    // prejdeme si vsetky smery a nastavime direction step
    Object.keys(Direction).filter((v) => isNaN(Number(v))).forEach((_key, index) => {

      const radians = THREE.MathUtils.degToRad(index * 15)
      this.directionStep[index] = new THREE.Vector2(Math.sin(radians), Math.cos(radians))

    })

    const { minStep, maxStep, minStepMobile, maxStepMobile } = aimConfig.shake

    if (MobileDetector.isMobile()) {

      this.minStepAuto = minStepMobile
      this.maxStepAuto = maxStepMobile

    } else {

      this.minStepAuto = minStep
      this.maxStepAuto = maxStep

    }

  }

  /**
   * Nastavenie aktualnej zotrvacnosti
   * @param percent - Aktuane percento v zotrvacnosti
   */
  private setActualLag(percent: number): void {

    // ease out cubic
    const percentChanged = 1 - Math.pow(1 - percent, 3)

    this.actualLag.set(
      percentChanged * this.lagOffsetOriginal.x,
      percentChanged * this.lagOffsetOriginal.y
    )

  }

  /**
   * Spustenie zotrvacnosti
   */
  private setLagStart(): void {

    this.frameLag = aimConfig.lag.frames
    const lagCoef = aimConfig.lag.coef
    this.lagOffsetOriginal.set(this.lastMoveOffset.x * lagCoef, this.lastMoveOffset.y * lagCoef)

  }

  /**
   * Spravovanie zotrvacnosti
   * @param x - X
   * @param y - Y
   */
  private manageLag(x: number, y: number): void {

    if (!aimConfig.lag.active) return

    const moving = x !== 0 || y !== 0

    if (!moving && (this.frameLag === 0 || this.movingInLag)) {

      this.movingInLag = false
      this.setLagStart()

    }

    if (this.frameLag > 0) {

      this.frameLag -= 1

      if (moving) this.movingInLag = true

      const percent = this.frameLag / aimConfig.lag.frames
      this.setActualLag(percent)

    }

  }

  /**
   * zmenime poziciu aiming pointu podla hracovho inputu
   */
  private updateAimingPosition(): void {

    if (MobileDetector.isMobile()) {

      // MOBILNA CAST
      const x = store.getters['MovementState/getPositionX'] * this.joystickStep.x
      const y = store.getters['MovementState/getPositionY'] * this.joystickStep.y

      this.moveAimingPoint(x, -y, true)
      return

    }

    // WEBOVA CAST
    if (!this.isInitShiftDone) {

      this.lastMousePosition = this.actualMousePosition.clone()
      this.makeInitAimingShift()

    }


    if (!disciplinePhasesManager.phaseAim.isLocked) {

      this.diffHelperVector.set(
        this.lastMousePosition.x - this.actualMousePosition.x,
        this.lastMousePosition.y - this.actualMousePosition.y
      )

      this.moveAimingPoint(
        -1 * this.diffHelperVector.x * this.mouseStep.x,
        this.diffHelperVector.y * this.mouseStep.y,
        true
      )

      this.lastMousePosition = this.actualMousePosition.clone()

    }

  }

  /**
   * Kontrola limitov pri zameriavani na osi X
   * @param x - hodnota na osi X
   * @returns Nova hodnota v limitoch
   */
  private checkAimLimitX(x: number): number {

    const { minX, maxX } = this.limitsToMove
    let newX = x

    if (newX < minX) newX = minX
    if (newX > maxX) newX = maxX

    return newX

  }

  /**
   * Kontrola limitov pri zameriavani na osi Y
   * @param y - hodnota na osi Y
   * @returns Nova hodnota v limitoch
   */
  private checkAimLimitY(y: number): number {

    const { minY, maxY } = this.limitsToMove
    let newY = y

    if (newY < minY) newY = minY
    if (newY > maxY) newY = maxY

    return newY

  }

  /**
   * moves aiming point
   * @param x - how much on X axis should we move
   * @param y - how much on Y axis shoud we move
   * @param withLag - Ci sa riesi aj zotrvacnost alebo nie
   */
  public moveAimingPoint(x: number, y: number, withLag = false): void {

    if (withLag) {

      // najskor nastavime aka by mala byt zotrvacnost (moze vyjst aj nulova)
      this.manageLag(x, y)

      // potom si poznacime offset pre buduce potreby
      this.lastMoveOffset.set(x, y)

      // a nakoniec pripocitame aktualnu hodnotu zotrvacnosti
      x += this.actualLag.x
      y += this.actualLag.y

    }

    this.aimingPoint.position.x = this.checkAimLimitX(this.aimingPoint.position.x - x)
    this.aimingPoint.position.y = this.checkAimLimitY(this.aimingPoint.position.y + y)

    if (aimConfig.debug.showTargetPoint) {

      // pripocitavame aj vietor
      const point = wind.getNewPoint(this.aimingPoint.position)
      this.moveTargetPoint(point)

    }
    if (aimConfig.debug.showTargetPointOriginal) {

      this.moveTargetPoint(this.aimingPoint.position, true)

    }

  }

  /**
   * Pohyb bodu na terci
   * @param aimPoint - Bod, z ktoreho sa bude pocitat bod na terci
   * @param targetPointOriginal - Ci posuvame originalny bod bez vetra alebo nie
   */
  public moveTargetPoint(point: THREE.Vector3, targetPointOriginal = false): void {

    // ked nejde o original, ale o bod ovplyvneny vetrom, tak musime skontrolovat limity
    if (!targetPointOriginal) {

      point.x = this.checkAimLimitX(point.x)
      point.y = this.checkAimLimitY(point.y)

    }

    // spravime raycast kvoli debugu
    this.raycast.set(point, this.RAYCAST_DIR_VECTOR)

  }

  /**
   * posunieme pociatocnu poziciu aiming bodu pri myske
   */
  public makeInitAimingShift(forced = false, position = new THREE.Vector3()): void {

    if (this.isInitShiftDone && !forced) return
    console.log('shift')
    this.isInitShiftDone = true

    this.aimingPoint.position.x = position.x
    this.aimingPoint.position.y = position.y

  }

  /**
   * registrujeme mouse event aby sme mohli zbierat poziciu
   */
  public setMouseStep(): void {

    if (MobileDetector.isMobile()) return

    this.gameSize.set(
      document.getElementById('game-container')?.clientWidth ?? 1,
      document.getElementById('game-container')?.clientHeight ?? 1
    )

    const { movementRange } = aimConfig
    this.mouseStep.set(
      (movementRange.x / this.gameSize.x) / this.screenToMaxTarget.x,
      (movementRange.y / this.gameSize.y) / this.screenToMaxTarget.y
    )

  }

  /**
   * Co sa stane pri aktualizovanie citlivosti mierenia
   */
  public onUpdateAimSensitivity = (): void => {

    const { sensitivity } = aimConfig
    const aimSensitivityValue = settings.getSetting(SettingsTypes.aimSensitivity)

    // web
    this.screenToMaxTarget = sensitivity.screenToMaxTarget[aimSensitivityValue]
    this.setMouseStep()

    // mobil
    this.joystickStep = sensitivity.joystickStep[aimSensitivityValue]

    console.log(
      'Bola nastavena citlivost mierenia na..',
      'screenToMaxTarget: ', this.screenToMaxTarget,
      'joystickStep: ', this.joystickStep,
    )

  }

  /**
   * Creates target point which we move around
   */
  public createAimingPoint(): void {

    if (game.scene.getObjectByName(this.AIMING_TARGET_POINT)) return
    const geometry = new THREE.SphereGeometry(aimConfig.scopeRadius)
    const material = new THREE.MeshBasicMaterial({
      color: new THREE.Color(0xFFFF00)
    })

    this.aimingPoint = new THREE.Mesh(geometry, material)
    this.aimingPoint.name = this.AIMING_TARGET_POINT

    if (!aimConfig.debug.showAimingPoint) this.aimingPoint.visible = false

    this.aimingPoint.position.copy(aimConfig.defaultAimingPoint)

    game.scene.add(this.aimingPoint)

  }

  /**
   * odstranime target point zo sceny
   */
  public removeObjectsFromScene(): void {

    this.aimingPoint.geometry.dispose()

    if (this.aimingPoint.material instanceof Array) {

      this.aimingPoint.material.forEach((material: THREE.Material) => material.dispose())

    } else {

      this.aimingPoint.material.dispose()

    }

    game.scene.remove(this.aimingPoint)

  }

  /**
   * Resetovanie property objektu
   */
  public reset(): void {

    this.isInitShiftDone = false
    this.setLimitsToMove()
    this.deviation = 0
    this.direction = Direction.N

  }

}

export const aimingDirectionManager = new AimingDirectionManager()
