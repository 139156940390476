import {
  THREE,
  AnimationsManager,
  game,
  fpsManager,
  playersManager,
  gsap,
  corePhasesManager,
  CorePhases,
  minigameConfig
} from '@powerplay/core-minigames'
import {
  gameConfig,
  animationsConfig
} from '../../config'
import {
  ModelsNames,
  PlayerStates
} from '../../types'
import { AthleteAnimationManager } from '.'
import { Gun } from '../equipment/Gun'
import { pigeon } from '../equipment/Pigeon'

/**
 * Trieda pre atleta
 */
export class Athlete {

  /** 3D objekt lyziara - cela scena */
  public athleteObject = new THREE.Object3D()

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /** Manager pre animacne stavy */
  public athleteAnimationManager = new AthleteAnimationManager(this)

  /** Stav hraca */
  private state = PlayerStates.none

  /** Ci ide o hratelneho atleta alebo nie */
  public playable = false

  /** Cas v cieli */
  public finalTime = minigameConfig.dnfValue

  /** Prefix pre pohlavie a 3d objekty */
  protected prefixSex = ''

  /** gun */
  public gun = new Gun(this)

  /** Objekt pre vlasy */
  protected hair!: THREE.Mesh

  /** Objekt pre cop */
  protected hairTail!: THREE.Mesh

  /** Typ vlasov */
  protected hairType = 1

  /** Index materialu,  hlavne ked ide o superov */
  public materialIndex = 0

  /**
   * Konstruktor
   * @param uuid - UUID spera
   */
  public constructor(public uuid = '') {}

  /**
   * Nastavenie stavu atleta
   * @param state - stav
   * @param maxRandomDelay - maximalna hodnota randomu delayu od 0
   */
  public setState(state: PlayerStates, maxRandomDelay = 0): void {

    const random = THREE.MathUtils.randFloat(0, maxRandomDelay)

    if (random === 0) {

      this.state = state

    } else {

      gsap.to({}, {
        duration: random,
        callbackScope: this,
        onComplete: () => {

          this.state = state
          console.log('state random', state, this.uuid, random)

        }
      })

    }

  }

  /**
   * Zistenie ci je aktualne dany stav
   * @param state -stav
   * @returns True, ak ano
   */
  public isState(state: PlayerStates): boolean {

    return this.state === state

  }

  /**
   * Vratenie objektu atleta
   * @returns Objekt atleta
   */
  protected getObject(): THREE.Object3D {

    // mechanika bude v zdedenej classe
    return new THREE.Object3D

  }

  /**
   * Vytvorenie lyziara
   * @param athleteName - Meno alteta
   */
  public create(athleteName: string, position = gameConfig.startPosition, rotation = gameConfig.startRotation): void {

    this.playable = playersManager.getPlayerById(this.uuid)?.playable || false
    this.athleteObject = this.getObject()
    game.scene.add(this.athleteObject)

    if (this.playable) pigeon.create()

    // animacie
    this.animationsManager = new AnimationsManager(
      this.athleteObject,
      animationsConfig,
      game.animations.get(ModelsNames.athlete),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    // threeJS Section
    this.athleteObject.position.set(position.x, position.y, position.z)
    this.athleteObject.rotation.set(rotation.x, rotation.y, rotation.z)
    this.athleteObject.name = athleteName


    // hands zatial nepotrebujeme
    const handsMesh = this.athleteObject.getObjectByName('hands')
    if (handsMesh) handsMesh.visible = false

  }

  /**
   * Vytvorenie luku a sipu
   */
  public createTools(): void {

    this.gun.create()

  }

  /**
   * Vratenie rotacie lyziara
   * @returns Quaternion lyziara
   */
  public getQuaternion(): THREE.Quaternion {

    return this.athleteObject.quaternion

  }

  /**
   * Vratenie pozicie atleta
   * @returns Pozicia atleta
   */
  public getPosition(): THREE.Vector3 {

    return this.athleteObject.position

  }

  /**
   * Aktualizovanie hraca po vykonani fyziky
   */
  public updateAfterPhysics(): void {

    if (!corePhasesManager.isActivePhaseByType(CorePhases.discipline)) return

    this.athleteAnimationManager.update()
    this.gun.update()

    if (this.playable) pigeon.update()

  }

  /**
   * Aktualizovanie hraca pred vykonanim fyziky
   * @param actualPhase - Aktualna faza
   */
  public updateBeforePhysics(): void {

    // zatial nic

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    this.animationsManager.update(delta)
    this.gun.updateAnimations(delta)

  }

  /**
   * Vypocet attrStrength
   * @returns attrStrength - number
   */
  public getAthleteAttributeStrength(): number {

    return playersManager.getPlayerById(this.uuid)?.attribute.total || 0

  }

  /**
   * reset atleta
   * @param hardReset - True, ak sa resetuje na dalsiu hru, nie iba na dalsi pokus v hre
   */
  public reset(hardReset = false): void {

    this.state = hardReset ? PlayerStates.prepareIntro : PlayerStates.idle
    this.animationsManager.resetAll()
    this.athleteAnimationManager.reset()
    this.finalTime = 0
    this.gun.reset()

  }

}
