import {
  flagsConfig,
  gameConfig
} from '@/app/config'
import {
  CANNON,
  game,
  gsap,
  THREE,
  type CannonNamedBody,
  fpsManager
} from '@powerplay/core-minigames'


/**
 * Trieda pre prostredie
 */
export class WorldEnv {

  /** Fyzicke body pre podlahu */
  public floorPhysicsBody?: CannonNamedBody

  /** Tween pre vlajky */
  private flagsTween?: gsap.core.Tween

  /** Mesh pre oblaky */
  private skyboxCloudsMesh!: THREE.Mesh

  /** Ci je povolene hybanie vlajkami */
  public movingFlagsEnabled = true

  /** Ci je povolene hybanie skyboxom */
  public movingSkyboxEnabled = true

  /** options pre meshe vlajok */
  private flagMeshesOptions: {mesh: THREE.Mesh, indexFrom: number, indexTo: number, actualRotation: number}[] = []

  /** progress vlnenia vlajok */
  private progress = 0

  /** Lightmapa vlajok pre hybanie */
  private flagsLightMap!: THREE.Texture | null

  /** Pomocny objekt pre tiene */
  private shadowHelperObject = new THREE.Object3D()

  /** Hlavna pozicia pre tiene */
  private shadowsMainPosition = new THREE.Vector3(
    gameConfig.startPosition.x + (gameConfig.nextPositionShift * 2),
    gameConfig.startPosition.y,
    gameConfig.startPosition.z
  )

  /**
   * Vytvorenie prostredia
   */
  public create(): void {

    const shape = new CANNON.Box(new CANNON.Vec3(100, 100, 0.1))
    this.floorPhysicsBody = new CANNON.Body({
      mass: 0,
      shape
    }) as CannonNamedBody

    this.floorPhysicsBody.name = 'floor'
    this.floorPhysicsBody.type = CANNON.BODY_TYPES.STATIC
    this.floorPhysicsBody.position.set(0, -0.5, 0)
    this.floorPhysicsBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), Math.PI / 2)
    game.physics.addBody(this.floorPhysicsBody)
    // game.getMesh('TargetHitBox').visible = false

    // oblaky
    this.skyboxCloudsMesh = game.getMesh('SkyboxDay_Skybox_Clouds')
    this.skyboxCloudsMesh.matrixAutoUpdate = true
    const cloudsMaterial = this.skyboxCloudsMesh.material as THREE.MeshBasicMaterial
    const cloudsTexture = cloudsMaterial.map
    if (cloudsTexture) {

      cloudsTexture.wrapS = THREE.ClampToEdgeWrapping
      cloudsTexture.wrapT = THREE.ClampToEdgeWrapping

    }
    game.getMesh('SkyboxDay_Skybox_FG').renderOrder = 1

    const mesh = game.getMesh('flags_Flag_Base')
    const material = mesh.material as THREE.MeshBasicMaterial
    this.flagsLightMap = material.lightMap
    this.setFlags()

    // schovame mieritko pred jeho inicializaciou
    const reticle = game.getObject3D('Trap_Reticle_Mesh004')
    reticle.visible = false

    // schovame aj hit marker pre mieritko
    const reticleHitMarker = game.getObject3D('envDynamic_Trap_HitMarker')
    reticleHitMarker.visible = false

    game.scene.add(this.shadowHelperObject)
    game.shadowsManager.attachPlaneToObject(this.shadowHelperObject)
    this.shadowHelperObject.matrixAutoUpdate = true
    this.manageShadowsPosition()

  }

  /**
   * nastavenie vlajok
   */
  public setFlags(): void {

    const mesh = game.getMesh('flags_Flag_Base')

    for (let i = 1; i <= 12; i += 1) {

      const clonedMesh = mesh.clone()
      clonedMesh.geometry = mesh.geometry.clone()
      clonedMesh.matrixAutoUpdate = true
      clonedMesh.name = `${mesh.name}_cloned_${i}`
      const stringNumber = i < 10 ? `0${i}` : i
      const object3D = game.getObject3D(`Flag_0${stringNumber}`)
      object3D.scale.set(1, 1, 1)
      object3D.rotation.y -= Math.PI / 2
      object3D.position.y += 0.5
      object3D.add(clonedMesh)
      const random = THREE.MathUtils.randInt(0, 8)
      this.flagMeshesOptions.push({
        mesh: clonedMesh,
        indexFrom: random,
        indexTo: random + 1,
        actualRotation: 1
      })

      // musime dat na nulu prvy influence, lebo nam to bude robit potom problemy
      if (clonedMesh.morphTargetInfluences) clonedMesh.morphTargetInfluences[0] = 0

      this.changeUVs(
        clonedMesh,
        flagsConfig.flagsToUse[i - 1].x,
        flagsConfig.flagsToUse[i - 1].y
      )

    }

    mesh.visible = false

  }

  /**
   * Zmena UV
   * @param mesh - Mesh na ktorom menime UVcka
   * @param indexU - index pre u koordinat
   * @param indexV - index pre v koordinat
   */
  private changeUVs(mesh: THREE.Mesh, indexU: number, indexV: number): void {

    const { horizontalValue, verticalValue } = flagsConfig
    const uvAttribute = mesh.geometry.attributes.uv
    for (let i = 0; i < uvAttribute.count; i++) {

      const u = uvAttribute.getX(i) + (horizontalValue * indexU)
      const v = uvAttribute.getY(i) + (verticalValue * indexV)

      uvAttribute.setXY(i, u, v)

    }

    uvAttribute.needsUpdate = true

  }

  /**
     * Hybanie vlajkami
     */
  private waveFlags(): void {

    let nullifyProgress = false
    this.flagMeshesOptions.forEach(({ mesh, indexFrom, indexTo, actualRotation }, index) => {

      // optimalizacia, aby sa zbytocne vlajky nehybali, ktore nevidime
      if (!mesh.morphTargetInfluences) return

      mesh.morphTargetInfluences[indexFrom] = 1 - this.progress
      mesh.morphTargetInfluences[indexTo] = this.progress

      mesh.rotateY(this.flagMeshesOptions[index].actualRotation > 0 ?
        flagsConfig.rotationSpeed :
        -flagsConfig.rotationSpeed)


      if (this.progress >= 1) {

        mesh.morphTargetInfluences[indexFrom] = 0
        mesh.morphTargetInfluences[indexTo] = 1

        this.flagMeshesOptions[index].indexFrom += 1
        if (this.flagMeshesOptions[index].indexFrom >= 10) this.flagMeshesOptions[index].indexFrom = 0

        this.flagMeshesOptions[index].indexTo += 1
        if (this.flagMeshesOptions[index].indexTo >= 10) this.flagMeshesOptions[index].indexTo = 0


        const changeDir = Math.random() > flagsConfig.rotationChangeProbability

        if (changeDir || mesh.rotation.y > flagsConfig.rotationMax || mesh.rotation.y < flagsConfig.rotationMin) {

          this.flagMeshesOptions[index].actualRotation = actualRotation * -1

        }

        nullifyProgress = true

      }

    })

    if (nullifyProgress) this.progress = 0

    this.progress += (flagsConfig.updateSpeed / fpsManager.fpsLimit)

  }

  /**
     * Aktualizacia vlajok
     */
  public updateFlags(): void {

    this.waveFlags()

    if (this.flagsLightMap) this.flagsLightMap.offset.x -= flagsConfig.lightMapSpeed

  }

  /**
   * Sprava oblakov
   */
  public manageSkyboxClouds() {

    if (!this.skyboxCloudsMesh || !this.movingSkyboxEnabled) return
    this.skyboxCloudsMesh.rotateY(-gameConfig.skyboxCloudsOffsetStep)

  }

  /**
   * Nastavenie pozicie pre tiene
   */
  public manageShadowsPosition(specialPosition?: THREE.Vector3): void {

    if (specialPosition) {

      // menime poziciu
      this.shadowHelperObject.position.set(
        specialPosition.x,
        specialPosition.y,
        specialPosition.z
      )

    } else {

      this.shadowHelperObject.position.set(
        this.shadowsMainPosition.x,
        this.shadowsMainPosition.y,
        this.shadowsMainPosition.z
      )

    }

  }

}

export const worldEnv = new WorldEnv()
