/**
* author Mark Kellogg - http://www.github.com/mkkellogg
*/
import { THREE } from '@powerplay/core-minigames'

type NodesObjectType = {
  attribute: THREE.BufferAttribute | null,
  offset: number,
  count: number
}

type CustomUniformObjectType = {
  type: string,
  value: THREE.Vector4 | null
}

type CustomUniformsType = {
  trailLength?: CustomUniformObjectType,
  verticesPerNode?: CustomUniformObjectType,
  minID?: CustomUniformObjectType,
  maxID?: CustomUniformObjectType,
  dragTexture?: CustomUniformObjectType,
  maxTrailLength?: CustomUniformObjectType,
  textureTileFactor?: CustomUniformObjectType,
  headColor?: CustomUniformObjectType,
  tailColor?: CustomUniformObjectType,
  trailTexture?: CustomUniformObjectType
}

type PositionAndOrientationObjectType = {
  position: THREE.Vector3,
  tangent: THREE.Vector3
}

function getMatrix3FromMatrix4(matrix3: THREE.Matrix3, matrix4: THREE.Matrix4) {

  const e = matrix4.elements
  matrix3.set(
    e[0], e[1], e[2],
    e[4], e[5], e[6],
    e[8], e[9], e[10]
  )

}

/**
 * Trail Renderer
 */
export class TrailRenderer extends THREE.Object3D {

  public active = false
  public orientToMovement = false
  public scene: THREE.Scene
  public geometry!: THREE.BufferGeometry
  public nodeCenters: THREE.Vector3[] = []
  public lastNodeCenter: THREE.Vector3 | null = null
  public currentNodeCenter: THREE.Vector3 | null = null
  public lastOrientationDir: THREE.Vector3 | null = null
  public nodeIDs: number[] = []
  public currentLength = 0
  public currentEnd = 0
  public currentNodeID = 0
  public advanceFrequency = 60
  public advancePeriod = 0
  public lastAdvanceTime = 0
  public paused = false
  public pauseAdvanceUpdateTimeDiff = 0
  public length = 0
  public dragTexture = 0
  public targetObject = new THREE.Object3D
  public material!: THREE.ShaderMaterial
  public VerticesPerNode = 0
  public localHeadGeometry: THREE.Vector3[] = []
  public FacesPerNode = (this.VerticesPerNode - 1) * 2
  public FaceIndicesPerNode = 0
  public vertexCount = 0
  public faceCount = 0
  public mesh!: THREE.Mesh | null
  public isActive = false
  private tempMatrix4 = new THREE.Matrix4()
  private emptyPositionAndRotationObject = {
    position: new THREE.Vector3(),
    tangent: new THREE.Vector3()
  }
  private tempQuaternion = new THREE.Quaternion()
  private tempOffset = new THREE.Vector3()
  private readonly RIGHT_VECTOR = new THREE.Vector3(1, 0, 0)
  private tempMatrix3 = new THREE.Matrix3()
  private tempPosition = new THREE.Vector3()
  private worldOrientation = new THREE.Vector3()
  private tempDirection = new THREE.Vector3()
  private tempLocalHeadGeometry: THREE.Vector3[] = []

  /**
   * Konstruktor
   * @param scene - Scena
   * @param orientToMovement - Orient to movement
   */
  public constructor(scene: THREE.Scene, orientToMovement: boolean) {

    super()
    if (orientToMovement) this.orientToMovement = true
    this.scene = scene
    this.advancePeriod = 1 / this.advanceFrequency

    for (let i = 0; i < TrailRenderer.MaxHeadVertices; i++) {

      this.tempLocalHeadGeometry.push(new THREE.Vector3())

    }

  }

  /**
   * Nastavenie advance frequency
   * @param advanceFrequency - Advance frequency
   */
  public setAdvanceFrequency(advanceFrequency: number) {

    this.advanceFrequency = advanceFrequency
    this.advancePeriod = 1.0 / this.advanceFrequency

  }

  /**
   * Inicializacia
   * @param material - Material
   * @param length - Dlzka efektu
   * @param dragTexture - Drag textura
   * @param localHeadWidth - local head width
   * @param localHeadGeometry - local head geometry
   * @param targetObject - Target objekt pre trail
   */
  public initialize(
    material: THREE.ShaderMaterial,
    length: number,
    dragTexture: boolean,
    localHeadWidth: number,
    localHeadGeometry: THREE.Vector3[],
    targetObject: THREE.Object3D
  ) {

    this.deactivate()
    this.destroyMesh()

    this.length = (length > 0) ? length + 1 : 0
    this.dragTexture = (!dragTexture) ? 0 : 1
    this.targetObject = targetObject

    this.initializeLocalHeadGeometry(localHeadWidth, localHeadGeometry)

    for (let i = 0; i < this.length; i++) {

      this.nodeIDs[i] = -1
      this.nodeCenters[i] = new THREE.Vector3()

    }

    this.material = material

    this.initializeGeometry()
    this.initializeMesh()

    this.material.uniforms.trailLength.value = 0
    this.material.uniforms.minID.value = 0
    this.material.uniforms.maxID.value = 0
    this.material.uniforms.dragTexture.value = this.dragTexture
    this.material.uniforms.maxTrailLength.value = this.length
    this.material.uniforms.verticesPerNode.value = this.VerticesPerNode
    this.material.uniforms.textureTileFactor.value = new THREE.Vector2(1.0, 1.0)

    this.reset()

  }

  /**
   * Inicializacia local head geometry
   * @param localHeadWidth - local head width
   * @param localHeadGeometry - local head geometry
   */
  public initializeLocalHeadGeometry(localHeadWidth: number, localHeadGeometry: THREE.Vector3[]) {

    this.localHeadGeometry = []
    if (!localHeadGeometry) {

      let halfWidth = localHeadWidth || 1.0
      halfWidth = halfWidth / 2.0
      this.localHeadGeometry.push(new THREE.Vector3(-halfWidth, 0, 0))
      this.localHeadGeometry.push(new THREE.Vector3(halfWidth, 0, 0))
      this.VerticesPerNode = 2

    } else {

      this.VerticesPerNode = 0
      for (let i = 0; i < localHeadGeometry.length && i < TrailRenderer.MaxHeadVertices; i++) {

        const vertex = localHeadGeometry[ i ]
        if (vertex && vertex instanceof THREE.Vector3) {

          const vertexCopy = new THREE.Vector3()
          vertexCopy.copy(vertex)
          this.localHeadGeometry.push(vertexCopy)
          this.VerticesPerNode++

        }

      }

    }
    this.FacesPerNode = (this.VerticesPerNode - 1) * 2
    this.FaceIndicesPerNode = this.FacesPerNode * 3

  }

  /**
   * Inicializacia geometrie
   */
  public initializeGeometry() {

    this.vertexCount = this.length * this.VerticesPerNode
    this.faceCount = this.length * this.FacesPerNode

    const geometry = new THREE.BufferGeometry()

    const nodeIDs = new Float32Array(this.vertexCount)
    const nodeVertexIDs = new Float32Array(this.vertexCount * this.VerticesPerNode)
    const positions = new Float32Array(this.vertexCount * TrailRenderer.PositionComponentCount)
    const nodeCenters = new Float32Array(this.vertexCount * TrailRenderer.PositionComponentCount)
    const uvs = new Float32Array(this.vertexCount * TrailRenderer.UVComponentCount)
    const indices = new Uint32Array(this.faceCount * TrailRenderer.IndicesPerFace)

    const nodeIDAttribute = new THREE.BufferAttribute(nodeIDs, 1)
    geometry.setAttribute('nodeID', nodeIDAttribute)

    const nodeVertexIDAttribute = new THREE.BufferAttribute(nodeVertexIDs, 1)
    geometry.setAttribute('nodeVertexID', nodeVertexIDAttribute)

    const nodeCenterAttribute = new THREE.BufferAttribute(nodeCenters, TrailRenderer.PositionComponentCount)
    geometry.setAttribute('nodeCenter', nodeCenterAttribute)

    const positionAttribute = new THREE.BufferAttribute(positions, TrailRenderer.PositionComponentCount)
    geometry.setAttribute('position', positionAttribute)

    const uvAttribute = new THREE.BufferAttribute(uvs, TrailRenderer.UVComponentCount)
    geometry.setAttribute('uv', uvAttribute)

    const indexAttribute = new THREE.BufferAttribute(indices, 1)
    geometry.setIndex(indexAttribute)

    this.geometry = geometry

  }

  /**
   * zero vertices
   */
  public zeroVertices() {

    const positions = this.geometry.getAttribute('position') as THREE.BufferAttribute
    for (let i = 0; i < this.vertexCount; i++) {

      const index = i * 3
      const array: number[] = positions.array as number[]
      array[index] = 0
      array[index + 1] = 0
      array[index + 2] = 0

    }
    positions.needsUpdate = true
    positions.updateRange.count = -1

  }

  /**
   * zero indices
   */
  public zeroIndices() {

    const indices = this.geometry.getIndex() as THREE.BufferAttribute
    for (let i = 0; i < this.faceCount; i++) {

      const index = i * 3
      const array: number[] = indices.array as number[]
      array[ index ] = 0
      array[ index + 1 ] = 0
      array[ index + 2 ] = 0

    }
    indices.needsUpdate = true
    indices.updateRange.count = -1

  }

  /**
   * Form initial faces
   */
  public formInitialFaces() {

    this.zeroIndices()
    const indices = this.geometry.getIndex() as THREE.BufferAttribute
    for (let i = 0; i < this.length - 1; i++) {

      this.connectNodes(i, i + 1)

    }
    indices.needsUpdate = true
    indices.updateRange.count = -1

  }

  /**
   * Inicializacia meshu
   */
  public initializeMesh() {

    this.mesh = new THREE.Mesh(this.geometry, this.material)
    this.mesh.matrixAutoUpdate = false

  }

  /**
   * Vymazanie meshu
   */
  public destroyMesh() {

    if (this.mesh) {

      this.scene.remove(this.mesh)
      this.mesh = null

    }

  }

  /**
   * Resetovanie
   */
  public reset() {

    this.currentLength = 0
    this.currentEnd = -1
    this.lastNodeCenter = null
    this.currentNodeCenter = null
    this.lastOrientationDir = null
    this.currentNodeID = 0
    this.formInitialFaces()
    this.zeroVertices()
    this.geometry.setDrawRange(0, 0)

  }

  /**
   * Aktualizacia uniforms
   */
  public updateUniforms() {

    if (this.currentLength < this.length) {

      this.material.uniforms.minID.value = 0

    } else {

      this.material.uniforms.minID.value = this.currentNodeID - this.length

    }
    this.material.uniforms.maxID.value = this.currentNodeID
    this.material.uniforms.trailLength.value = this.currentLength
    this.material.uniforms.maxTrailLength.value = this.length
    this.material.uniforms.verticesPerNode.value = this.VerticesPerNode

  }

  /**
   * Pokracovanie v efekte
   */
  public advance() {

    this.targetObject.updateMatrixWorld()
    this.tempMatrix4.copy(this.targetObject.matrixWorld)
    this.advanceWithTransform(this.tempMatrix4)
    this.updateUniforms()

  }

  /**
   * Pokracovanie v efekte s poziciou a orientaciou
   * @param nextPosition - Pozicia
   * @param orientationTangent - Orientacia
   */
  public advanceWithPositionAndOrientation(nextPosition: THREE.Vector3, orientationTangent: THREE.Vector3) {

    this.advanceGeometry({ position: nextPosition,
      tangent: orientationTangent }, null)

  }

  /**
   * Pokracovanie v efekte s transformaciou
   * @param transformMatrix - Transformacna matica
   */
  public advanceWithTransform(transformMatrix: THREE.Matrix4) {

    this.advanceGeometry(
      this.emptyPositionAndRotationObject,
      transformMatrix
    )

  }

  /**
   * Pokracovanie v efekte v geometrii
   * @param positionAndOrientation - Pozicia a orientacia
   * @param transformMatrix - transformacna matica
   */
  public advanceGeometry(
    positionAndOrientation: PositionAndOrientationObjectType,
    transformMatrix: THREE.Matrix4 | null
  ) {

    const nextIndex = this.currentEnd + 1 >= this.length ? 0 : this.currentEnd + 1
    if (transformMatrix) {

      this.updateNodePositionsFromTransformMatrix(nextIndex, transformMatrix)

    } else {

      this.updateNodePositionsFromOrientationTangent(
        nextIndex,
        positionAndOrientation.position,
        positionAndOrientation.tangent
      )

    }

    if (this.currentLength >= 1) {

      this.connectNodes(this.currentEnd, nextIndex)
      if (this.currentLength >= this.length) {

        const disconnectIndex = this.currentEnd + 1 >= this.length ? 0 : this.currentEnd + 1
        this.disconnectNodes(disconnectIndex)

      }

    }

    if (this.currentLength < this.length) {

      this.currentLength++

    }

    this.currentEnd++
    if (this.currentEnd >= this.length) {

      this.currentEnd = 0

    }

    if (this.currentLength >= 1) {

      if (this.currentLength < this.length) {

        this.geometry.setDrawRange(0, (this.currentLength - 1) * this.FaceIndicesPerNode)

      } else {

        this.geometry.setDrawRange(0, this.currentLength * this.FaceIndicesPerNode)

      }

    }
    this.updateNodeID(this.currentEnd, this.currentNodeID)
    this.currentNodeID++

  }

  /**
   * Aktualny cas
   * @returns Cas
   */
  public currentTime() {

    return performance.now() / 1000

  }

  /**
   * Pauznutie efektu
   */
  public pause() {

    if (!this.paused) {

      this.paused = true
      this.pauseAdvanceUpdateTimeDiff = this.currentTime() - this.lastAdvanceTime

    }

  }

  /**
   * Pokracovanie v efekte po pauznuti
   */
  public resume() {

    if (this.paused) {

      this.paused = false
      this.lastAdvanceTime = this.currentTime() - this.pauseAdvanceUpdateTimeDiff

    }

  }

  /**
   * Aktualizacia efektu
   */
  public update() {

    if (!this.paused) {

      const time = this.currentTime()
      if (!this.lastAdvanceTime) this.lastAdvanceTime = time
      if (time - this.lastAdvanceTime > this.advancePeriod) {

        this.advance()
        this.lastAdvanceTime = time

      } else {

        this.updateHead()

      }

    }

  }

  /**
   * Aktualizovanie hlavy
   * @returns
   */
  public updateHead() {

    if (this.currentEnd < 0) return
    this.targetObject.updateMatrixWorld()
    this.tempMatrix4.copy(this.targetObject.matrixWorld)
    this.updateNodePositionsFromTransformMatrix(this.currentEnd, this.tempMatrix4)

  }

  /**
   * Aktualizovanie node ID
   * @param nodeIndex - Index node
   * @param id - id
   */
  public updateNodeID(nodeIndex: number, id: number) {

    this.nodeIDs[ nodeIndex ] = id
    const nodeIDs = this.geometry.getAttribute('nodeID') as THREE.BufferAttribute
    const nodeVertexIDs = this.geometry.getAttribute('nodeVertexID') as THREE.BufferAttribute
    for (let i = 0; i < this.VerticesPerNode; i++) {

      const baseIndex = nodeIndex * this.VerticesPerNode + i
      const arrayNodeIDs: number[] = nodeIDs.array as number[]
      arrayNodeIDs[baseIndex] = id
      const arrayNodeVertexIDs: number[] = nodeVertexIDs.array as number[]
      arrayNodeVertexIDs[baseIndex] = i

    }
    nodeIDs.needsUpdate = true
    nodeVertexIDs.needsUpdate = true
    nodeIDs.updateRange.offset = nodeIndex * this.VerticesPerNode
    nodeIDs.updateRange.count = this.VerticesPerNode
    nodeVertexIDs.updateRange.offset = nodeIndex * this.VerticesPerNode
    nodeVertexIDs.updateRange.count = this.VerticesPerNode

  }

  /**
   * Aktualizovanie node center
   * @param nodeIndex - index node
   * @param nodeCenter - node center
   */
  public updateNodeCenter(nodeIndex: number, nodeCenter: THREE.Vector3) {

    this.lastNodeCenter = this.currentNodeCenter
    this.currentNodeCenter = this.nodeCenters[ nodeIndex ]
    this.currentNodeCenter.copy(nodeCenter)
    const nodeCenters = this.geometry.getAttribute('nodeCenter') as THREE.BufferAttribute
    for (let i = 0; i < this.VerticesPerNode; i++) {

      const baseIndex = (nodeIndex * this.VerticesPerNode + i) * 3
      const array: number[] = nodeCenters.array as number[]
      array[baseIndex] = nodeCenter.x
      array[baseIndex + 1] = nodeCenter.y
      array[baseIndex + 2] = nodeCenter.z

    }
    nodeCenters.needsUpdate = true
    nodeCenters.updateRange.offset = nodeIndex * this.VerticesPerNode * TrailRenderer.PositionComponentCount
    nodeCenters.updateRange.count = this.VerticesPerNode * TrailRenderer.PositionComponentCount

  }

  /**
   * Aktualizovanie pozicie z orientacie
   * @param nodeIndex - index node
   * @param nodeCenter - node center
   * @param orientationTangent - orientacia
   */
  public updateNodePositionsFromOrientationTangent(
    nodeIndex: number,
    nodeCenter: THREE.Vector3,
    orientationTangent: THREE.Vector3
  ) {

    for (let i = 0; i < TrailRenderer.MaxHeadVertices; i++) {

      this.tempLocalHeadGeometry[i].set(0, 0, 0)

    }

    const positions = this.geometry.getAttribute('position')
    this.updateNodeCenter(nodeIndex, nodeCenter)
    this.tempOffset.copy(nodeCenter)
    this.tempOffset.sub(TrailRenderer.LocalHeadOrigin)
    this.tempQuaternion.setFromUnitVectors(this.RIGHT_VECTOR, orientationTangent)

    for (let i = 0; i < this.localHeadGeometry.length; i++) {

      this.tempLocalHeadGeometry[i].copy(this.localHeadGeometry[ i ])
      this.tempLocalHeadGeometry[i].applyQuaternion(this.tempQuaternion)
      this.tempLocalHeadGeometry[i].add(this.tempOffset)

    }

    for (let i = 0; i < this.localHeadGeometry.length; i++) {

      const positionIndex = ((this.VerticesPerNode * nodeIndex) + i) * TrailRenderer.PositionComponentCount
      const transformedHeadVertex = this.tempLocalHeadGeometry[i]
      const array: number[] = positions.array as number[]
      array[positionIndex] = transformedHeadVertex.x
      array[positionIndex + 1] = transformedHeadVertex.y
      array[positionIndex + 2] = transformedHeadVertex.z

    }

    positions.needsUpdate = true

  }

  /**
   * Aktualizacia node z transformacnej matice
   * @param nodeIndex - index node
   * @param transformMatrix - transformacna matica
   */
  public updateNodePositionsFromTransformMatrix(nodeIndex: number, transformMatrix: THREE.Matrix4) {

    for (let i = 0; i < TrailRenderer.MaxHeadVertices; i++) {

      this.tempLocalHeadGeometry[i].set(0, 0, 0)

    }

    const positions = this.geometry.getAttribute('position') as THREE.BufferAttribute
    this.tempPosition.set(0, 0, 0)
    this.tempPosition.applyMatrix4(transformMatrix)
    this.updateNodeCenter(nodeIndex, this.tempPosition)
    for (let i = 0; i < this.localHeadGeometry.length; i++) {

      this.tempLocalHeadGeometry[i].copy(this.localHeadGeometry[ i ])

    }

    for (let i = 0; i < this.localHeadGeometry.length; i++) {

      this.tempLocalHeadGeometry[i].applyMatrix4(transformMatrix)

    }

    if (this.lastNodeCenter && this.orientToMovement) {

      getMatrix3FromMatrix4(this.tempMatrix3, transformMatrix)
      this.worldOrientation.set(0, 0, -1)
      this.worldOrientation.applyMatrix3(this.tempMatrix3)
      if (this.currentNodeCenter) this.tempDirection.copy(this.currentNodeCenter)
      this.tempDirection.sub(this.lastNodeCenter)
      this.tempDirection.normalize()

      if (this.tempDirection.lengthSq() <= .0001 && this.lastOrientationDir) {

        this.tempDirection.copy(this.lastOrientationDir)

      }

      if (this.tempDirection.lengthSq() > .0001) {

        if (!this.lastOrientationDir) this.lastOrientationDir = new THREE.Vector3()
        this.tempQuaternion.setFromUnitVectors(this.worldOrientation, this.tempDirection)
        if (this.currentNodeCenter) this.tempOffset.copy(this.currentNodeCenter)
        for (let i = 0; i < this.localHeadGeometry.length; i++) {

          this.tempLocalHeadGeometry[i].sub(this.tempOffset)
          this.tempLocalHeadGeometry[i].applyQuaternion(this.tempQuaternion)
          this.tempLocalHeadGeometry[i].add(this.tempOffset)

        }

      }

    }

    for (let i = 0; i < this.localHeadGeometry.length; i++) {

      const positionIndex = ((this.VerticesPerNode * nodeIndex) + i) * TrailRenderer.PositionComponentCount
      const array: number[] = positions.array as number[]
      array[positionIndex] = this.tempLocalHeadGeometry[i].x
      array[positionIndex + 1] = this.tempLocalHeadGeometry[i].y
      array[positionIndex + 2] = this.tempLocalHeadGeometry[i].z

    }
    positions.needsUpdate = true
    positions.updateRange.offset = nodeIndex * this.VerticesPerNode * TrailRenderer.PositionComponentCount
    positions.updateRange.count = this.VerticesPerNode * TrailRenderer.PositionComponentCount

  }

  /**
   * Pospajanie nodes
   * @param srcNodeIndex - index src node
   * @param destNodeIndex - index dest node
   * @returns - Node
   */
  public connectNodes(srcNodeIndex: number, destNodeIndex: number): NodesObjectType {

    const returnObj: NodesObjectType = {
      attribute: null,
      offset: 0,
      count: -1
    }

    const indices = this.geometry.getIndex() as THREE.BufferAttribute
    for (let i = 0; i < this.localHeadGeometry.length - 1; i++) {

      const srcVertexIndex = (this.VerticesPerNode * srcNodeIndex) + i
      const destVertexIndex = (this.VerticesPerNode * destNodeIndex) + i
      const faceIndex = (
        (srcNodeIndex * this.FacesPerNode) + (i * TrailRenderer.FacesPerQuad )
      ) * TrailRenderer.IndicesPerFace
      const array: number[] = indices.array as number[]
      array[faceIndex] = srcVertexIndex
      array[faceIndex + 1] = destVertexIndex
      array[faceIndex + 2] = srcVertexIndex + 1
      array[faceIndex + 3] = destVertexIndex
      array[faceIndex + 4] = destVertexIndex + 1
      array[faceIndex + 5] = srcVertexIndex + 1

    }
    indices.needsUpdate = true
    indices.updateRange.count = -1
    returnObj.attribute = indices
    returnObj.offset = srcNodeIndex * this.FacesPerNode * TrailRenderer.IndicesPerFace
    returnObj.count = this.FacesPerNode * TrailRenderer.IndicesPerFace
    return returnObj

  }

  /**
   * Odpojenie nodes
   * @param srcNodeIndex - index src
   * @returns - Node
   */
  public disconnectNodes(srcNodeIndex: number): NodesObjectType {

    const returnObj: NodesObjectType = {
      attribute: null,
      offset: 0,
      count: -1
    }

    const indices = this.geometry.getIndex() as THREE.BufferAttribute
    for (let i = 0; i < this.localHeadGeometry.length - 1; i++) {

      const faceIndex = (
        (srcNodeIndex * this.FacesPerNode) + (i * TrailRenderer.FacesPerQuad)
      ) * TrailRenderer.IndicesPerFace
      const array: number[] = indices.array as number[]
      array[faceIndex] = 0
      array[faceIndex + 1] = 0
      array[faceIndex + 2] = 0
      array[faceIndex + 3] = 0
      array[faceIndex + 4] = 0
      array[faceIndex + 5] = 0

    }
    indices.needsUpdate = true
    indices.updateRange.count = -1
    returnObj.attribute = indices
    returnObj.offset = srcNodeIndex * this.FacesPerNode * TrailRenderer.IndicesPerFace
    returnObj.count = this.FacesPerNode * TrailRenderer.IndicesPerFace
    return returnObj

  }

  /**
   * Deaktivacia efektu
   */
  public deactivate() {

    if (this.isActive) {

      if (this.mesh) this.scene.remove(this.mesh)
      this.isActive = false

    }

  }

  /**
   * Aktivacia efektu
   */
  public activate() {

    if (!this.isActive) {

      if (this.mesh) this.scene.add(this.mesh)
      this.isActive = true

    }

  }

  /**
   * Vytvorenie shader materialu
   * @param vertexShader - vertex shader
   * @param fragmentShader - fragment shader
   * @param customUniforms - custom uniforms
   * @returns Shader material
   */
  public static createMaterial(vertexShader: string, fragmentShader: string, customUniforms: CustomUniformsType) {

    customUniforms = customUniforms || {}
    customUniforms.trailLength = {
      type: 'f',
      value: null
    }
    customUniforms.verticesPerNode = {
      type: 'f',
      value: null
    }
    customUniforms.minID = {
      type: 'f',
      value: null
    }
    customUniforms.maxID = {
      type: 'f',
      value: null
    }
    customUniforms.dragTexture = {
      type: 'f',
      value: null
    }
    customUniforms.maxTrailLength = {
      type: 'f',
      value: null
    }
    customUniforms.textureTileFactor = {
      type: 'v2',
      value: null
    }
    customUniforms.headColor = {
      type: 'v4',
      value: new THREE.Vector4()
    }
    customUniforms.tailColor = {
      type: 'v4',
      value: new THREE.Vector4()
    }

    vertexShader = vertexShader || TrailRenderer.Shader.BaseVertexShader
    fragmentShader = fragmentShader || TrailRenderer.Shader.BaseFragmentShader

    return new THREE.ShaderMaterial({
      uniforms: customUniforms,
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent: true,
      alphaTest: 0.5,
      blending: THREE.CustomBlending,
      blendSrc: THREE.SrcAlphaFactor,
      blendDst: THREE.OneMinusSrcAlphaFactor,
      blendEquation: THREE.AddEquation,
      depthTest: true,
      depthWrite: false,
      side: THREE.DoubleSide
    })

  }

  /**
   * Vytvorenie materialu iba s farbou
   * @param customUniforms - custom uniforms
   * @returns Material
   */
  public static createBaseMaterial(customUniforms: CustomUniformsType) {

    return TrailRenderer.createMaterial(
      TrailRenderer.Shader.BaseVertexShader,
      TrailRenderer.Shader.BaseFragmentShader,
      customUniforms
    )

  }

  /**
   * Vytvorenie materialu s texturou
   * @param customUniforms - custom uniforms
   * @returns Material
   */
  public static createTexturedMaterial(customUniforms: CustomUniformsType) {

    customUniforms.trailTexture = {
      type: 't',
      value: null
    }
    return TrailRenderer.createMaterial(
      TrailRenderer.Shader.TexturedVertexShader,
      TrailRenderer.Shader.TexturedFragmentShader,
      customUniforms
    )

  }

  /**
   * GET: Max hrad vertices
   */
  public static get MaxHeadVertices() {

    return 128

  }

  /**
   * GET: Local head origin
   */
  public static get LocalHeadOrigin() {

    return new THREE.Vector3(0, 0, 0)

  }

  /**
   * GET: position component count
   */
  public static get PositionComponentCount() {

    return 3

  }

  /**
   * GET: UV component count
   */
  public static get UVComponentCount() {

    return 2

  }

  /**
   * GET: indices per face
   */
  public static get IndicesPerFace() {

    return 3

  }

  /**
   * GET: faces per quad
   */
  public static get FacesPerQuad() {

    return 2

  }

  /**
   * Shader
   */
  public static Shader = {

    get BaseVertexVars() {

      return [
        'attribute float nodeID;',
        'attribute float nodeVertexID;',
        'attribute vec3 nodeCenter;',
        'uniform float minID;',
        'uniform float maxID;',
        'uniform float trailLength;',
        'uniform float maxTrailLength;',
        'uniform float verticesPerNode;',
        'uniform vec2 textureTileFactor;',
        'uniform vec4 headColor;',
        'uniform vec4 tailColor;',
        'varying vec4 vColor;',
      ].join('\n')

    },

    get TexturedVertexVars() {

      return [
        this.BaseVertexVars,
        'varying vec2 vUV;',
        'uniform float dragTexture;',
      ].join('\n')

    },

    BaseFragmentVars: [
      'varying vec4 vColor;',
      'uniform sampler2D trailTexture;',
    ].join('\n'),

    get TexturedFragmentVars() {

      return [
        this.BaseFragmentVars,
        'varying vec2 vUV;'
      ].join('\n')

    },

    get VertexShaderCore() {

      return [
        'float fraction = (maxID - nodeID) / (maxID - minID);',
        'vColor = (1.0 - fraction) * headColor + fraction * tailColor;',
        'vec4 realPosition = vec4((1.0 - fraction) * position.xyz + fraction * nodeCenter.xyz, 1.0); ',
      ].join('\n')

    },

    get BaseVertexShader() {

      return [
        this.BaseVertexVars,
        'void main() { ',
        this.VertexShaderCore,
        'gl_Position = projectionMatrix * viewMatrix * realPosition;',
        '}'
      ].join('\n')

    },

    get BaseFragmentShader() {

      return [
        this.BaseFragmentVars,
        'void main() { ',
        'gl_FragColor = vColor;',
        '}'
      ].join('\n')

    },

    get TexturedVertexShader() {

      return [
        this.TexturedVertexVars,
        'void main() { ',
        this.VertexShaderCore,
        'float s = 0.0;',
        'float t = 0.0;',
        'if (dragTexture == 1.0) { ',
        '   s = fraction *  textureTileFactor.s; ',
        '     t = (nodeVertexID / verticesPerNode) * textureTileFactor.t;',
        '} else { ',
        '    s = nodeID / maxTrailLength * textureTileFactor.s;',
        '     t = (nodeVertexID / verticesPerNode) * textureTileFactor.t;',
        '}',
        'vUV = vec2(s, t); ',
        'gl_Position = projectionMatrix * viewMatrix * realPosition;',
        '}'
      ].join('\n')

    },

    get TexturedFragmentShader() {

      return [
        this.TexturedFragmentVars,
        'void main() { ',
        'vec4 textureColor = texture2D(trailTexture, vUV);',
        'gl_FragColor = vColor * textureColor;',
        '}'
      ].join('\n')

    }
  }

}