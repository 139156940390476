import { THREE } from '@powerplay/core-minigames'
/**
 * Konfig pre mierenie
 */
export const aimConfig = {

  /** debug zobrazenia */
  debug: {

    /** zobraz mieritko pred tercom */
    showAimingPoint: false,

    /** zobraz mieritko na terci s vetrom */
    showTargetPoint: false,

    /** Zobraz mieritko na terci bez vetra */
    showTargetPointOriginal: false

  },

  /** startovacia pozicia mierenia */
  targetOriginPosition: new THREE.Vector3(0, 2, 10),

  /** Kolko frameov bude na zaciatku mierenia freeznuty input hraca */
  inputFreezeFramesOnStart: 10,

  /** Pozicia stredu terca */
  targetCenterPosition: new THREE.Vector3(0, 2, 10),

  /** Nastavenia kamery */
  camera: {

    /** zakladne nastavenia kamery */
    near: 0.03,
    far: 1000,
    fovDesktop: 50,
    fovMobile: 50,

    /** offset wrappera */
    wrapperPositionOffset: new THREE.Vector3(0, 1.8, -1), // je to offset od pozicie hraca
    aimCircleOffset: new THREE.Vector3(0, 0, 2),

  },

  /** mieridlo */
  aimCircle: {
    // povodny priemer
    diameter: 0.06,
    // polcas zmeny velkosti pri strelbe
    duration: 0.1,
    // na aky priemer sa ma zvacsit
    biggerDiameter: 0.08
  },

  /** mieridlovy obdlznik */
  aimRectangle: {
    x: 0.2,
    y: 0.2
  },

  /** o kolko od origin pointu sa vieme max. posunut */
  movementRange: new THREE.Vector2(100, 40),

  /** citlivost */
  sensitivity: {

    /** po kolkych % obrazovky presunieme target o 100% - podla nastavenia citlivosti mierenia */
    screenToMaxTarget: [
      new THREE.Vector2(3, 3),
      new THREE.Vector2(2, 2),
      new THREE.Vector2(1, 1),
      new THREE.Vector2(0.8, 0.8),
      new THREE.Vector2(0.6, 0.6),
      new THREE.Vector2(0.45, 0.45),
      new THREE.Vector2(0.3, 0.3),
    ],

    /** citlivost joystiku - podla nastavenia citlivosti mierenia */
    joystickStep: [
      new THREE.Vector2(0.04, 0.04),
      new THREE.Vector2(0.05, 0.05),
      new THREE.Vector2(0.06, 0.06),
      new THREE.Vector2(0.07, 0.07),
      new THREE.Vector2(0.08, 0.08),
      new THREE.Vector2(0.09, 0.09),
      new THREE.Vector2(0.1, 0.1),
    ],

    /** citlivost mysi pri lock */
    pointerLockMaxStep: new THREE.Vector2(3, 3)

  },

  /** o kolko sa posunie zaciatocna pozicia na zaciatku. Neprekroci limit z movementRange */
  startAimPosition: {

    vertical: {
      max: 0.1,
      min: -0.1
    },

    horizontal: {
      max: 0.1,
      min: -0.1
    }

  },

  /** default pre aimingPoint poziciu */
  defaultAimingPoint: new THREE.Vector3(40, 0, 90),

  /** polomer mieritka */
  scopeRadius: 0.03,

  /** zotrvacnost/dojazd pri miereni */
  lag: {

    /** Ci je aktivna/y */
    active: false,

    /** Pocet frameov, kolko bude trvat */
    frames: 30,

    // koeficient pre vynasobenie posledneho offsetu
    coef: 2

  },

  /** trasenie ruk */
  shake: {

    /** Ci je aktivne */
    active: false,

    /** Od kolkatich frameov sa zacne triast */
    framesMin: 5 * 30,

    /** Od kolkatich frameov uz nastal maximalny efekt trasenia */
    framesMax: 10 * 30,

    /** ako casto sa bude menit smer */
    directionFrames: 6,

    /** sanca, ze sa nebude menit smer v % */
    chanceSameDirection: 0.3,

    /** koeficient vychylovania */
    coef: 2,

    /** minimalna rychlost vychylovania na osi x a y */
    minStep: 0.002,
    minStepMobile: 0.0005,

    /** maximalna rychlost vychylovania na osi x a y */
    maxStep: 0.004,
    maxStepMobile: 0.0025,

  },

  /** Rotacia playera v % horizontalne a vertikalne */
  rotationPercentPlayer: {

    horizontal: 1.2,
    vertical: 0.6

  },

  /** Posun hraca v ramci wrappera, aby sedela zbran a mieritko */
  shiftPlayerOnX: 0.07,

  /** Delay pre releasnutie holuba po zakricani, v sekundach */
  pigeonReleaseDelay: 0.2

}