import {
  THREE,
  game
} from '@powerplay/core-minigames'
import { TrailRenderer } from './TrailRenderer'
import { trailConfig } from './config'

/**
 * Trieda pre trail
 */
export class TrailManager {

  /** Trail renderer */
  public trailRenderer?: TrailRenderer

  /**
   * Nastavenie trailu
   * @param trailTarget - Target pre trail
   */
  public setUp(trailTarget: THREE.Object3D) {

    const trailHeadGeometry = this.getCircleGeometry()

    // create the trail renderer object
    this.trailRenderer = new TrailRenderer(game.scene, false)

    // set how often a new trail node will be added and existing nodes will be updated
    this.trailRenderer.setAdvanceFrequency(trailConfig.advanceFrequency)

    // create material for the trail renderer
    const trailMaterial = TrailRenderer.createBaseMaterial({})
    const { color } = trailConfig
    trailMaterial.uniforms.headColor.value.set(color.r, color.g, color.b, color.a)
    trailMaterial.uniforms.tailColor.value.set(color.r, color.g, color.b, color.a)
    trailMaterial.depthWrite = false

    // initialize the trail
    this.trailRenderer.initialize(trailMaterial, trailConfig.length, false, 0, trailHeadGeometry, trailTarget)

    // aby bol viditelny zo vsetkych uhlov
    if (this.trailRenderer.mesh) this.trailRenderer.mesh.frustumCulled = false

  }

  private getCircleGeometry(): THREE.Vector3[] {

    // specify points to create planar trail-head geometry
    const trailHeadGeometry = []
    const twoPI = Math.PI * 2
    let index = 0
    const scale = trailConfig.scale
    const inc = twoPI / 32.0

    for (let i = 0; i <= twoPI + inc; i += inc) {

      trailHeadGeometry[ index ] = new THREE.Vector3(Math.cos(i) * scale, Math.sin(i) * scale, 0)
      index += 1

    }

    return trailHeadGeometry

  }

  /**
   * Aktivovanie alebo deaktivovanie efektu
   * @param active - True, ak chceme aktivovat
   */
  public setActive(active: boolean): void {

    if (active) {

      this.trailRenderer?.activate()

    } else {

      this.trailRenderer?.deactivate()

    }

  }

  /**
   * Aktualizovanie efektu
   */
  public update(): void {

    if (this.trailRenderer?.isActive) this.trailRenderer?.update()

  }

  /**
   * Resetovanie efektu
   */
  public reset(): void {

    this.trailRenderer?.reset()

  }

}

export const trailManager = new TrailManager()