import {
  HairColorTypes,
  THREE
} from '@powerplay/core-minigames'
import { MinigameVersionTypes } from '../types'

/** Konfig pre nastavenie fyziky a aj celej hry */
export const gameConfig = {

  /** typ verzie minihry - POZOR, v inite a specialData moze tuto hondotu prepisat */
  minigameVersionType: MinigameVersionTypes.a,

  /** Pocet pokusov */
  numberOfAttempts: 10,
  numberOfAttemptsTraining: 5,

  /**
   * startovacia pozicia a rotacia
   */
  startPosition: new THREE.Vector3(6.4, 0.075, 0), // i * -3.2 po x osi su ostatne stanovista
  startRotation: new THREE.Vector3(0, 0, 0),
  nextPositionShift: -3.2,

  /**
   * defaultna rychlost animacii
   */
  defaultAnimationSpeed: 1 / 2,

  /**
   * Ci mam aktivny skip do ciela a na akej pozicii to zacina
   */
  skipToFinish: {

    active: false,
    percent: 0.85

  },

  /** fix pozicie y pre hraca, aby nebol vo vzduchu */
  yPlayerCorrection: 0,

  /** Lerp pre quaternion kamery pocas behu */
  cameraQuaternionLerp: 0.15,

  /** Farby vlasov */
  hairColors: {
    [HairColorTypes.blonde]: '#fcd681',
    [HairColorTypes.red]: '#ba2c13',
    [HairColorTypes.orange]: '#f56e07',
    [HairColorTypes.brown]: '#6b3e24',
    [HairColorTypes.black]: '#303233',
    [HairColorTypes.purple]: '#7146bd',
    [HairColorTypes.green]: '#61d64d',
    [HairColorTypes.blue]: '#3bdced',
    [HairColorTypes.pink]: '#ff5eb7',
  },

  /** Ako rychlo sa hybu oblaky - rotacia v radianoch */
  skyboxCloudsOffsetStep: 0.0003,

  /** Efekt muzzle flash - vystrel z pusky */
  muzzleFlash: {

    // Hodnota v %, kedy sa nezobrazuje efekt, ak je 0, mal by sa zobrazovat stale
    randomNotShow: 0,

    // pocet frameov, kolko sa ma efekt zobrazovat (davame vzdy +1 ako chceme nastavit)
    framesToShow: 2,

    // minimalna velkost efektu
    minScale: 0.6

  }

}
