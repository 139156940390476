import {
  animationsConfigGun,
  gameConfig,
  gunShotSmokeNearGunConfig,
} from '@/app/config'
import {
  AnimationsManager,
  THREE,
  fpsManager,
  game
} from '@powerplay/core-minigames'
import {
  ModelsNames,
  ParticleNames,
  PlayerStates
} from '@/app/types'
import type { Athlete } from '../athlete'
import { particleEffects } from '@/app/ParticleEffects'

/**
 * Trieda pre luk
 */
export class Gun {

  /** Rig pre luk, resp vsetky kosti a skinned meshe v jednom */
  public gunRig!: THREE.Object3D

  /** Zakladny animacny manager */
  public animationsManager!: AnimationsManager

  /** kost na ruke pre napajanie R */
  private gunHelperBoneR?: THREE.Object3D

  /** kost na ruke pre napajanie L */
  private gunHelperBoneL?: THREE.Object3D

  /** kost na ramene pre napajanie R */
  private gunHelperShoulderR?: THREE.Object3D

  /** aktivna kost pre napajanie */
  private gunHelperBoneActive?: THREE.Object3D

  /** temp objekt pre efekty vystelu zo zbrane - nachadza sa v linii pusky s muzzle flash meshom */
  private gunEffectsHelperObject = new THREE.Object3D()

  /** Mesh pre efekt muzzle flash */
  private muzzleFlashMesh!: THREE.Mesh

  /** Helper object for muzzle */
  private gunFrontPosition = new THREE.Vector3()

  /** Helper object for muzzle */
  private gunBackPosition = new THREE.Vector3()

  /** Timer pre muzzle flash, kedy sa ma vypnut */
  private muzzleFlashTimer = 0

  /**
   * Konstruktor
   * @param athlete - Atlet
   */
  public constructor(private athlete: Athlete) {}

  /**
   * Vytvorenie zbrane
   */
  public create(): void {

    // musime dat rig zbrane prec z objektu hraca
    this.gunRig = this.athlete.athleteObject.getObjectByName('gun_rig') as THREE.Object3D
    game.scene.add(this.gunRig)
    this.resetPositionRotationGunBone()

    // skinned meshu musime vypnut frustumCulled, lebo sa to sprava, akoby bol na povodnej pozicii
    const gunSuffix = this.athlete.playable ? '' : `_opponent_${this.athlete.materialIndex}`
    const gunSkinnedMesh = this.gunRig.getObjectByName(`gun_low${gunSuffix}`) as THREE.Object3D
    if (gunSkinnedMesh) {

      gunSkinnedMesh.frustumCulled = false
      gunSkinnedMesh.castShadow = true

    }

    // animacie
    this.animationsManager = new AnimationsManager(
      this.gunRig,
      animationsConfigGun,
      game.animations.get(ModelsNames.athlete),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    this.animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    this.animationsManager.resetSpeed()

    this.gunHelperBoneR = this.athlete.athleteObject.getObjectByName('gun_R_helper')
    this.gunHelperBoneL = this.athlete.athleteObject.getObjectByName('gun_L_helper')
    this.gunHelperShoulderR = this.athlete.athleteObject.getObjectByName('gun_shoulder_R_helper')

    // muzzle flash
    this.muzzleFlashMesh = game.getMesh('envDynamic_MuzzleFlash')
    this.muzzleFlashMesh.position.set(-0.132, 1.64, 1.085)
    this.muzzleFlashMesh.matrixAutoUpdate = true
    this.muzzleFlashMesh.visible = false
    this.athlete.athleteObject.add(this.muzzleFlashMesh)

    // pomocne objekty pre efekty pre pusku
    this.gunEffectsHelperObject.position.set(-0.132, 1.49, 0.1)
    this.athlete.athleteObject.add(this.gunEffectsHelperObject)

  }

  /**
   * Zobrazenie muzzle flash efektu
   */
  private showMuzzleFlashEffect(): void {

    // nedavame vzdy
    if (THREE.MathUtils.randFloat(0, 100) < gameConfig.muzzleFlash.randomNotShow) return

    this.muzzleFlashMesh.visible = true
    const scale = THREE.MathUtils.randFloat(gameConfig.muzzleFlash.minScale, 1)
    this.muzzleFlashMesh.scale.set(scale, scale, scale)
    console.log('muzzle flash bol som scaleom', scale)
    this.muzzleFlashTimer = gameConfig.muzzleFlash.framesToShow

  }

  /**
   * Kontrola muzzle flash efektu, ci sa nema vypnut
   */
  private checkMuzzleFlash(): void {

    if (this.muzzleFlashTimer <= 0) return

    this.muzzleFlashTimer -= 1
    if (this.muzzleFlashTimer === 0) {

      this.muzzleFlashMesh.visible = false

    }

  }

  /**
   * Zobrazenie particle efektov prachu/dymu po vystrele z pusky
   */
  private showParticlesEffectsAfterShot(): void {

    this.muzzleFlashMesh.getWorldPosition(this.gunFrontPosition)
    this.gunEffectsHelperObject.getWorldPosition(this.gunBackPosition)
    const dir = this.gunFrontPosition.clone().sub(this.gunBackPosition)
    const position = this.gunFrontPosition.add(dir.normalize().multiplyScalar(0.1))

    // Efekt hned za hlavnou pusky
    particleEffects.startEmitter(
      ParticleNames.gunShotSmokeNearGun,
      gunShotSmokeNearGunConfig.emitFrames,
      position,
      dir.normalize(),
      gunShotSmokeNearGunConfig.multiplyVelocity,
    )

  }

  /**
   * Zobrazenie efektov po vystrele
   */
  public showEffectsAfterShot(): void {

    this.showMuzzleFlashEffect()
    this.showParticlesEffectsAfterShot()

  }

  /**
   * Nastavenie gunRigu po zmene helpera kosti
   * @returns
   */
  public setGunRigAfterChangeBoneHelper(): void {

    if (!this.gunHelperBoneActive || !this.gunRig) return

    this.gunHelperBoneActive.add(this.gunRig)

  }

  /**
   * Resetovanie pozicie a rotacie pre hlavnu kost pusky
   */
  private resetPositionRotationGunBone(): void {

    const gunBone = this.gunRig.getObjectByName('gun_main')
    if (gunBone) {

      gunBone.position.set(0, 0, 0)
      gunBone.rotation.set(0, 0, 0)

    }

  }

  /**
   * Kontrola helpera kosti pre pusku
   */
  private checkGunHelperBone(): void {

    let newGunHelperBone = this.gunHelperBoneL

    if (
      this.athlete.isState(PlayerStates.loading) ||
      this.athlete.isState(PlayerStates.idle) ||
      this.athlete.isState(PlayerStates.prepare) ||
      this.athlete.isState(PlayerStates.stand) ||
      this.athlete.isState(PlayerStates.walk) ||
      this.athlete.isState(PlayerStates.shoot)
    ) {

      newGunHelperBone = this.gunHelperBoneR

    }

    if (this.athlete.isState(PlayerStates.prepareIntro)) {

      newGunHelperBone = this.gunHelperShoulderR

    }

    if (!this.gunHelperBoneActive || (newGunHelperBone && newGunHelperBone.name !== this.gunHelperBoneActive.name)) {

      this.gunHelperBoneActive = newGunHelperBone
      this.setGunRigAfterChangeBoneHelper()

    }

    // musime resetovat poziciu a rotaciu kosti pusky, aby sa tam nedavali offsety, co tam nemaju co hladat
    this.resetPositionRotationGunBone()

  }

  /**
   * Aktualizovanie luku
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    // aktualizujeme animacie
    this.animationsManager.update(delta)

    this.checkGunHelperBone()

  }

  /**
   * Aktualizovanie luku
   */
  public update(): void {

    if (!this.athlete.playable) return

    this.checkMuzzleFlash()

  }

  /**
   * reset atleta
   */
  public reset(): void {

    this.animationsManager.resetAll()

  }

}
