<template>
  <div class="info-key-positioner">
    <arrow-animation
      v-if="showSpace"
      position="bottom"
      :style="{
        right: '0%',
        bottom: '2%',
        'transform-origin': 'right bottom',
        position: 'absolute',
        width: '200px',
        height: '250px',
        margin: '0 100px'
      }"
    />
    <tutorial-keyboard-keys
      v-if="showSpace"
      style="right:0%; bottom: 0%; transform-origin: right bottom; width: 200px; height: 250px;"
      :text="$t('webInfoShoot')"
      is-scaled
    >
      <div class="mouse-click" />
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showSpace"
      position="bottom"
      :style="{
        left: '0%',
        bottom: '2%',
        'transform-origin': 'left bottom',
        position: 'absolute',
        width: '200px',
        height: '250px',
        margin: '0 100px'
      }"
    />
    <tutorial-keyboard-keys
      v-if="showSpace"
      style="left:0%; bottom: 0%; transform-origin: left bottom"
      :text="$t('webInfoAim')"
      is-scaled
    >
      <div class="mouse-move" />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
/* eslint-disable max-len */
import {
  ArrowAnimation,
  TutorialKeyboardKeys
} from '@powerplay/core-minigames-ui-ssm'
import { mapGetters } from 'vuex'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialKeys',
  components: {
    TutorialKeyboardKeys,
    ArrowAnimation
  },
  computed: {
    ...mapGetters({
      isLeft: 'InputsState/isLeft',
      isShootTutorial: 'ActionButtonState/isShootTutorial',
      showTutorialInfo: 'TutorialState/getShowTutorialInfo'
    }),
    showSpace() {

      return this.isShootTutorial && this.showTutorialInfo

    }
  }
})
</script>

<style lang="less">
    .info-key-positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }
        .keyboard-vertical-keys {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                width: 100px;
            }
            .tutorial-keyboard-header {
                padding: 0 20px;
                width: 200px;
                margin-bottom: 40px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .mouse-move {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-MOVE.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            width: 126px;
            height: 132px;
        }
        .mouse-click {
            background-image: url('https://appspowerplaymanager.vshcdn.net/images/winter-sports/minigame/core-ui/tutorial/ICO-MOUSE-CLICK.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 20px 22px;
            width: 70px;
            height: 90px;
        }
        .tutorial-keyboard-keys.tutorial-keyboard-keys-ssm {
          margin: 0 100px;

          .tutorial-keyboard-header {
            .shrinking-text {
              padding: 0;
            }
          }

        }
    }
</style>
