import type { Module } from 'vuex'
import type { RootState } from '../index'


export interface ShellsState {
  shellsLeft: number,
  showShells: boolean
}

const initialState = () => ({
  shellsLeft: 0,
  showShells: false
})

const shellsState: Module<ShellsState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getShells: (moduleState: ShellsState) => moduleState,
  },

  mutations: {
    RESET: (moduleState: ShellsState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: ShellsState, newState: ShellsState) => {

      if (newState.shellsLeft !== undefined) moduleState.shellsLeft = newState.shellsLeft
      if (newState.showShells !== undefined) moduleState.showShells = newState.showShells

    },
  }
}

export default shellsState
