import type { ParticleEmitter } from '@powerplay/core-minigames'

export enum ParticleNames {
  pigeonShotSmoke = 'pigeonShotSmoke',
  gunShotSmokeNearGun = 'gunShotSmokeNearGun',
  // gunShotSmokeLong1 = 'gunShotSmokeLong',
  // gunShotSmokeLong2 = 'gunShotSmokeLong1',
  // gunShotSmokeLong3 = 'gunShotSmokeLong2',
  // gunShotSmokeLong4 = 'gunShotSmokeLong3',
  // gunShotSmokeLong5 = 'gunShotSmokeLong4',
}

export type ParticleEmitters = Partial<Record<ParticleNames, ParticleEmitter>>

export type ParticleEmitterTimerType = { [key in ParticleNames]: number }
