import type { AnimationsSettingRepository } from '@powerplay/core-minigames'
import {
  PlayerAnimationsNames,
  GunAnimationsNames
} from '../types'
/**
 * Konfig pre animacie
 */
export const animationsConfig: AnimationsSettingRepository = {

  [PlayerAnimationsNames.happy1]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy2]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.happy3]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.fistPump]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.idle]: {
    loop: true,
    starting: true
  },
  [PlayerAnimationsNames.loading]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.afterEmotion]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.afterHappy]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.prepare]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.sad]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.stand]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.unloading]: {
    loop: false,
    starting: false
  },
  [PlayerAnimationsNames.introClapDown]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.introClapUp]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.introHandL]: {
    loop: true,
    starting: false
  },
  [PlayerAnimationsNames.shoot]: {
    loop: false,
    starting: false
  }
}

/**
 * Konfig pre animacie luku
 */
export const animationsConfigGun: AnimationsSettingRepository = {

  [GunAnimationsNames.gunIdleHappy]: {
    loop: true,
    starting: true
  },
  [GunAnimationsNames.gunLoadWalk]: {
    loop: true,
    starting: false
  },
  [GunAnimationsNames.gunPrepare]: {
    loop: false,
    starting: false
  },
  [GunAnimationsNames.gunUnloading]: {
    loop: false,
    starting: false
  },
  [GunAnimationsNames.gunShoot]: {
    loop: false,
    starting: false
  },

}
