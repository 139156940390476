<template>
  <div
    v-if="shells.showShells"
    class="shells-counter-container"
    :style="[style]"
  >
    <div
      v-if="glow"
      class="yellow-glow"
    />
    <shrinking-text-box
      :text="$t('utShells')"
      :font-size="32"
      :width="210"
      :height="57"
      class="left-wrapper"
    />
    <div class="right-wrapper">
      <div class="shells-wrapper">
        <span
          class="shell"
          :class="shells.shellsLeft < 2 ? 'disabled' : ''"
        />
        <span
          class="shell"
          :class="shells.shellsLeft < 1 ? 'disabled' : ''"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { ShrinkingTextBox } from '@powerplay/core-minigames-ui-ssm'

export default defineComponent({
  name: 'ShellsCounter',
  components: {
    ShrinkingTextBox
  },
  props: {
    /**
     * Sets the styles. To make the native style binding work.
     */
    style: {
      type: Object,
      default: () => ({})
    },
    glow: {
      type: Boolean,
      default: false
    },
    time: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      shells: 'ShellsState/getShells'
    }),
  }

})
</script>

<style lang="less">

  .shells-counter-container {
    height: 70px;
    background-color: #232b46;
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    font-stretch: condensed;
    color: #fff;
    border-bottom: 3px solid #feb942;

    .left-wrapper {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3px 60px 0 60px;
      white-space: nowrap;
    }
    .right-wrapper {
      width: 110px;
      height: 100%;
      background-color: #4a4b75;
      display: flex;
      justify-content: center;
      align-items: center;

      .shells-wrapper {
        width: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .shell {
          width: 19px;
          height: 45px;
          background-image:
            url('https://appspowerplaymanager.vshcdn.net/images/summer-sports/minigame/trap/ui/ICO-SHELL-LARGE.png');
          background-repeat: no-repeat;
          background-size: cover;

          &.disabled {
            opacity: 0.5;
          }
        }
      }
      .time {
        padding-right: 10px;
      }
    }

    .yellow-glow {
      border: 3px solid #feb942;
      pointer-events: none;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      position: absolute;
      left: -3px;
      top: -3px;
    }

  }

</style>
