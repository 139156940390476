import {
  gsap,
  CameraStates,
  corePhasesManager,
  modes,
  cameraManager,
  audioManager,
  fpsManager
} from '@powerplay/core-minigames'
import {
  PlayerStates,
  type DisciplinePhaseManager,
  AudioNames,
  AudioGroups
} from '../../types'
import store from '@/store'
import { player } from '../../entities/athlete/player'
import { customCameraConfig } from '../../config'
import { startPhaseStateManager } from './StartPhaseStateManager'
import { disciplinePhasesManager } from '../DisciplinePhasesManager'
import { pigeon } from '@/app/entities/equipment/Pigeon'
import { opponentsManager } from '@/app/entities/athlete/opponent/OpponentsManager'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'
import { aimingDirectionManager } from '../AimPhase/AimingDirectionManager'
import { trainingTasks } from '@/app/modes/training'
import { particleEffects } from '@/app/ParticleEffects'

/**
 * Trieda pre startovaciu fazu
 */
export class StartPhaseManager implements DisciplinePhaseManager {

  /** ci sa deje nieco skipnutelne */
  private skippable = true

  /** ci uz je mozne odstartovat */
  public startable = false

  /** ci uz zobrazit ui player-info-avatar */
  private showName = false

  /** ci faza skoncila */
  public ended = false

  /** hodnota na ktorej stlacil */
  public clickedPower = 0

  /** Pocet frameov od zaciatku fazy */
  private framesInPhase = 0

  /** tween na skrytie odrazovej hlasky */
  public startingMessageTween?: gsap.core.Tween

  /** Premenna pre kameru */
  private cameraInPostIntroState = false

  /** zobrazenie bielej ziary */
  private showShine = false

  /**
   * Konstruktor
   * @param callbackEnd - callback na zavolanie po skonceni fazy
   */
  public constructor(private callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase(): void {

    this.storeState()
    /*
     * startPhaseStateManager.disableInputs()
     * startPhaseStateManager.enableStartInputs()
     */

  }

  /**
   * Zacatie fazy
   */
  public startPhase(): void {

    // vypneme castice a aj ich vymazeme, ak nejake ostali
    particleEffects.deleteAllParticles()

    if (this.ended) {

      this.reset()
      this.afterCameraDisciplineIntro()
      return

    }

    console.warn('starting start phase')

    // davame hlavne kvoli dalsim pokusom, kedze vo finish phase sa to vypina
    fpsManager.startCounting()

    if (
      corePhasesManager.disciplineActualAttempt === 1 &&
      (!modes.isTrainingMode() || !trainingTasks.nextAttemptAsRepeat)
    ) {

      audioManager.play(AudioNames.cheerClap)

    }

    // Zobrazit meno hraca v UI
    this.showName = !modes.isTutorial()
    this.setCameraForDisciplineIntro()

  }

  /**
   * Nastavenie kamery pre intro
   */
  private setCameraForDisciplineIntro(): void {

    if (customCameraConfig.start.enabled) {

      player.setGameCameraSettings(undefined, true)

    }

    if (modes.isTutorial()) {

      tutorialFlow.setObjectivesInit()
      if (corePhasesManager.disciplineActualAttempt !== 1) {

        this.afterCameraDisciplineIntro()
        return

      }
      aimingDirectionManager.update()
      this.finishPhase()
      return

    }

    if (
      corePhasesManager.disciplineActualAttempt !== 1 ||
      (modes.isTrainingMode() && trainingTasks.nextAttemptAsRepeat)
    ) {

      this.afterCameraDisciplineIntro()
      return

    }

    player.setState(PlayerStates.prepareIntro)
    opponentsManager.setStateForAllOpponents(PlayerStates.prepareIntro)

    cameraManager.setState(CameraStates.disciplineIntro)
    cameraManager.playTween(false, this.afterCameraDisciplineIntro)

  }

  /** pomocna metoda pre animacie */
  public getCameraInPostIntroState(): boolean {

    return this.cameraInPostIntroState

  }

  /**
   * Spravenie veci po konci disciplinoveho intra
   */
  private afterCameraDisciplineIntro = (): void => {

    this.skippable = false
    this.cameraInPostIntroState = true
    this.showTargetText()
    // Zrusit zobrazenie mena hraca v UI
    this.showName = false
    startPhaseStateManager.postIntroUiState()

    // toto nie je len kazdy 5. pokus, ale aj prvy
    const firstAnimation = disciplinePhasesManager.isFifthAttempt() &&
      (!modes.isTrainingMode() || !trainingTasks.nextAttemptAsRepeat)

    player.setState(firstAnimation ? PlayerStates.loading : PlayerStates.prepare)
    if (firstAnimation) trainingTasks.nextAttemptAsRepeat = false

    // superom zmenime animacie tiez, pricom vzdy vpravo od hraca musi mat stand animaciu a vzdy to je super c. 0
    opponentsManager.setStateForAllOpponents(PlayerStates.idle)
    opponentsManager.setState(0, PlayerStates.stand)

    if (modes.isTutorial() && corePhasesManager.disciplineActualAttempt === 1) return

    console.log('READY')
    this.finishPhase()

  }
  /**
   * Zobrazenie target \{number\} hlasky
   */
  private showTargetText(): void {

    const lastAttempt = corePhasesManager.disciplineActualAttempt === corePhasesManager.disciplineAttemptsCount
    const showFirstLine = false
    const showSecondLine = true
    const showThirdLine = (modes.isTrainingMode() && trainingTasks.nextAttemptAsRepeat) ? true : false

    startPhaseStateManager.resetTextMessageFinishedEmits(showFirstLine, showSecondLine, showThirdLine)
    store.commit('TextMessageState/SET_STATE', {
      showFirstLine,
      showSecondLine,
      showThirdLine,
      firstLineText: '',
      firstLineTextType: 1,
      secondLineTextType: lastAttempt ? 4 : 1,
      secondLineLeftNumber: corePhasesManager.disciplineActualAttempt,
      thirdLineText: 'text-repeat',
      showMessage: true,
      showType: 3
    })

    gsap.to({}, {
      onComplete: () => {

        store.commit(
          'MiniTableState/SET_STATE',
          {
            rowsData: [],
            show: false
          }
        )

      },
      duration: 2
    })

  }

  /**
   * Inputy pri starte
   */
  public handleInputs(): void {

    // skip intra
    if (this.skippable && this.framesInPhase > 10) {

      console.log('skippped')
      this.skippable = false
      cameraManager.skipTween()
      this.framesInPhase = 0

    }

  }

  /**
   * Update kazdy frame
   */
  public update(): void {

    if (!this.ended) this.framesInPhase++

    this.storeState()

  }

  /**
   * Ukoncene fazy
   */
  public finishPhase(): void {

    if (!this.ended) {

      console.warn('start phase ended')
      // this.decreaseAudienceNoise()

      this.ended = true
      this.callbackEnd()

      this.storeState()

      startPhaseStateManager.finishPhaseUiState()

      if (corePhasesManager.disciplineActualAttempt === 1) {

        audioManager.stopAudioByName(AudioNames.audienceQuiet)
        if (
          !audioManager.isAudioGroupPlaying(AudioGroups.commentators) &&
          !modes.isTrainingMode() &&
          !modes.isTutorial()
        ) {

          audioManager.play(AudioNames.commentatorBefore1stShot)

        }

      }

    }

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

  /**
   * UI update
   */
  private storeState(): void {

    store.commit('StartPhaseState/SET_STATE', {
      showName: this.showName,
      showPhase: !this.ended,
      attempt: corePhasesManager.disciplineActualAttempt,
      showShine: this.showShine
    })

  }

  /**
   * reset fazy
   */
  public reset(): void {

    store.commit('InputsState/SET_DISABLED', true)
    this.skippable = true
    this.startable = false
    this.showName = false
    this.ended = false
    this.clickedPower = 0
    this.framesInPhase = 0
    this.showShine = false
    this.startingMessageTween = undefined
    this.cameraInPostIntroState = false
    this.storeState()

    pigeon.reset()

  }

}
